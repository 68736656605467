<template>
    <div id="thotea">
        <Header :user="user" page="candidat" logo="thotea" />

        <div class="row">
            <back-button></back-button>
            <br>
            <div class="col-md-12 pb-2">
                <h2 class="textTitle pl-5">Les Candidats</h2>
                <div class="row pr-5 pl-5 mr-2 ml-2  align-items-center">
                    <button @click="registerCandidate()" class="btn btn-primary col-md-2 float-left text-white">
                        + Ajout candidat
                    </button>
                    <div class="col-md-8 "></div>
                    <div class=" col-md-2 input-group   float-right ">
                        <input class="form-control inputSearch " v-model="search" @keyup="searching"
                            placeholder="Recherche candidat" style="width: 50%;" />
                        <div class="input-group-append" style="background-color: #ffffff00;">
                            <span class="input-group-text"><i class="fas fa-search"></i></span>
                        </div>
                    </div>
                </div>
                <br>

                <div class="row pr-5 pl-5" style="max-height: 150vh; overflow-y: auto;">

                    <div class=" col-md-6" v-for="candidate in listCandidate" :key="candidate.id" align="center">
                        <div class="row   p-4 m-1" style="background-color: #ffffff33; ">
                            <div class="col-md-5 justify-content-center">
                                <img src="@/assets/thotea/Profil.png"
                                    style="width: 230px;float: right;margin-left: 50%;margin-top: 8%;" />
                            </div>
                            <div class="col-md-7">
                                <!-- <i class="fas fa-user" color="rgb(160, 10, 145)"></i> -->
                                <h4 class="text-white">{{ candidate.first_name }} {{ candidate.last_name }}</h4>
                                <br>
                                <p class="textTitle">Nationalité : {{ candidate.extension[0].nationality }} </p>
                                <p class="textTitle">Adresse : {{ candidate.extension[0].street_name }} {{
                                    candidate.extension[0].city }} </p>
                                <p class="textTitle">Mail : {{ candidate.email }}</p>
                                <p class="textTitle">No téléphone : {{
                                    formatPhoneNumber(candidate.extension[0].mobile_num, user.profile.mobile_num) }}</p>

                                <hr>

                                <div class="row justify-content-center" align="center">
                                    <router-link :to="{ name: 'voirResultat', params: { 'id': candidate.id } }"><button
                                            class="btn btn-info ">Voir résultat</button></router-link>&nbsp;
                                    <router-link :to="{ name: 'assignerCandidat', params: { 'id': candidate.id } }"><button
                                            type="button" class="btn btn-secondary ">Assigner</button></router-link>
                                    <button title="Archiver" class="btn btn-success" @click="openDeleteConfirmation(candidate)" 
                                        style="margin-left:5px ; border-color:red; background:#dc3545;">
                                        <i class="fa-solid fa-trash" color="white"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <!-- Confirmation de suppression -->
        <ConfirmDeleteCandidate
            v-if="showDeleteConfirmation"
            :title="selectedCandidate.first_name + ' ' + selectedCandidate.last_name"
            :type="'archiver '"
            :textConfirmButton="'Archiver'"
            :modalBackgroundColor="'#9a4c76'"
            :borderColor="'green'"
            :confirmButtonColor="'#dc3545'"
            :confirmButtonTextColor="'white'"
            :cancelButtonColor="'#17a2b8'"
            :cancelButtonTextColor="'white'"
            :overlayColor="'rgba(0,0,0,0.9)'"
            @confirm="handleDeleteConfirmation"
            @close="closeDeleteConfirmation"
        />
    </div>
</template>
<script>
import store from '@/store'
import Header from '@/components/shared/Header.vue';
import axios from 'axios';
import data from '@/data/data';
import { deleteUser } from '@/data/apiUser';
import BackButton from '@/components/shared/BackButton.vue';
import { formatPhoneNumber } from '@/data/formatPhoneNumber';
import { useToast } from "vue-toastification";
import ConfirmDeleteCandidate from '@/components/ConfirmDeleteModal/DeleteConfirmModal.vue'; 

const toast = useToast()
export default {
    name: 'list-candidate',
    components: { Header, BackButton , ConfirmDeleteCandidate },
    data() {
        return {
            listCandidate: [], // Liste affichée (candidat non archivé)
            candidates: [], // Liste complète des candidats
            user: store.state.user,
            search: '',

            // Gestion de la suppression
            showDeleteConfirmation: false,
            selectedCandidate: null,
        }
    },
    mounted() {
        axios.get(data.baseUrl + "authentication/api/api/allUsers/")
            .then(response => {
                
                this.candidates = response.data.filter(item => {
                    const notArchived = item.extension[0] && !item.extension[0].is_archived; // Accéder à is_archived dans extension[0]
                    const hasExtension = item.extension[0] !== undefined;
                    return notArchived && hasExtension;
                });
                this.listCandidate = [...this.candidates];
                console.log(this.listCandidate)
            }).catch(error => console.log(error));
    },
    methods: {
        formatPhoneNumber,
        searching() {
            if (this.search === "") {
                // Restaurer la liste filtrée (non archivés uniquement)
                this.listCandidate = [...this.candidates];
            } else {
                // Appliquer la recherche sur les candidats non archivés
                this.listCandidate = this.candidates.filter(item => {
                    return item.last_name.toLowerCase().includes(this.search.toLowerCase()) ||
                           item.first_name.toLowerCase().includes(this.search.toLowerCase());
                });
            }
        },
        registerCandidate() {
            this.$router.push("/register");
        },
         // Méthodes pour la suppression
        openDeleteConfirmation(candidate) {
            this.selectedCandidate = candidate;
            this.showDeleteConfirmation = true;
        },
        closeDeleteConfirmation() {
            this.showDeleteConfirmation = false;
            this.selectedCandidate = null;
        },
        async handleDeleteConfirmation() {
        if (this.selectedCandidate) {
            try {
            await deleteUser(this.selectedCandidate.id);
            // Supprimer le candidat de la liste complète et affichée
            this.candidates = this.candidates.filter(candidate => candidate.id !== this.selectedCandidate.id);
            this.listCandidate = this.listCandidate.filter(candidate => candidate.id !== this.selectedCandidate.id);
            toast.success('Candidat archivé avec succès');
            } catch (error) {
            console.error("Erreur lors de l'archivage :", error);
            toast.error("Une erreur est survenue lors de l'archivage du candidat.");
            }
        }
        this.closeDeleteConfirmation();
        },
    }
}
</script>

<style>
.inputSearch {
    background-color: rgba(255, 255, 255, 0);
    border-color: #fab833;
}

.row::-webkit-scrollbar {
    background: transparent;
}
</style>