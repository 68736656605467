<template>
    <div id="thotea">
        <Header :user="user" page="test" logo="thotea"/>
        <div class="p-3">
                <div class="row pl-5 pr-5 pt-3 align-items-center" >
                    <div class="col-md-4  p-3" id="timer">
                        <h4 class=" text-white" style="float: left;" id="timeRemaining" ><span class="textTitle">TEMPS</span><span class=" p-2">{{hours%24}}</span>:<span class="p-2">{{minutes%60}}</span>:<span class="p-2">{{seconds%60}}</span></h4>
                    </div>
                    <div class="col-md-4 pr-5 pl-5"  >
                        <div class="cardTitle p-2 pt-2">
                            <h4 v-if="questionnaire.questionnaireAssigned" > QUESTIONNAIRE : {{questionnaire.questionnaireAssigned.Title}}</h4>
                        </div>
                    </div>
                    <div class="col-md-1"></div>
                    <div class="col-md-3  pl-5 " >
                        <div class="progress barProgress" style="height: 20px;">
                            <div class="progress-bar pbTest" role="progressbar" :style="'width:'+(numeroQuestion*100)/nbreTotalQuestion+'% ;'" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <span class="text-white">Barre de progression {{ numeroQuestion}} / {{nbreTotalQuestion }}</span>
                    </div>
                </div>
                <p class="text-white info">Choisissez la ou les réponses qui vous semblent plausible et cliquez sur "Valider" pour valider votre réponse!</p>
            <div class="row">
                    <div class="col-md-12 pr-5 pl-5 pt-2">
                        <div class="slideshow-container pl-5 pr-5" v-show="evaluations.length!=0" id="slide" >
                            <div class="mySlides" v-for="(questionnaire, index) in evaluations" :key="questionnaire.id">
                                <!-- <div class="numbertext">1 / 3</div> -->
                                
                                <div class="card cardSlide" v-if="!showLoading">
                                
                                <h2 class="textTitle"> {{questionnaire.question}}</h2>
                                <div class=" card-content pl-5 pr-5" v-if="!showLoading">
                                    <div class="responses pl-5 pr-5" v-for="response in (questionnaire.answers.split('|||'))" :key="response">
                                        <button v-if="isAnswer(response)" class="btn btn-block btn-info p-3 textResponse" @click="checkResponse(response)">{{response}}</button>
                                        <button v-else class="btn btn-block btn-outline-info p-3 textResponse" @click="checkResponse(response)">{{response}}</button>
                                    </div>
                                    
                                </div>
                                <div class="text-center" v-else>
                                    <div class="spinner-border" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>

                                </div>
                                <button @click="validAnswer(index,  questionnaire.id)" class="btn btn-warning text-white mt-3 pt-4 pb-3 col-md-2 float-right" style="font-size: large;" :disabled="answer==''"> Valider</button>
                            </div>
                            
                            <div style="text-align:center" hidden>
                                <span class="dot" v-for="index in evaluations.length" :key="index" @click="currentSlide(index)"></span>
                            </div>
                        </div>
                        <br> 
                    </div>
                </div>  
        </div>   
            
    </div>
        <Chatroom />
</template>

<script setup>
import Chatroom from '@/components/chatroom/Chatroom.vue';
</script>
<script>
    import store from '@/store'
    import Header from '@/components/shared/Header.vue';
    import { useToast } from "vue-toastification";
    import axios from 'axios';
    import data from '@/data/data';
    import moment from 'moment';
    
    const toast = useToast()
      export default {
        name: 'testPage',
        props: {
         
        },
        components:{Header},
        
        async created(){
            await axios.get(data.baseUrl+"thotea/api/getAssignQuestionnaires/"+this.idQuestionnaire+"/")
                .then(response=>{
                    this.questionnaire  = response.data
                    if(this.questionnaire.isArchived){
                        this.alreadyArchived=true
                        this.$router.push("/")
                    }
                }).catch(error=>console.log(error))

            this.nbreTotalQuestion = this.questionnaire.numberOfQuestion
            
            const launchEnd = this.questionnaire.time*60*1000
            let currentTime = 0
            let interval  = setInterval(() =>{
                const launchTimer = launchEnd - currentTime
                if ((launchTimer)<=60000) //moins d'une minute
                    document.getElementById('timer').style.backgroundColor='red'
                if(launchTimer>=0){
                    this.seconds = parseInt(launchTimer/1000)
                    this.minutes = parseInt(this.seconds/60)
                    this.hours= parseInt(this.minutes/60)
                    currentTime +=1000 
                    
                }else{
                    clearInterval(interval);
                    toast.error('Le temps est écoulé')
                    this.getResult()
                }
            }, 1000)
            await axios.post(data.baseUrl+"thotea/GetEvualation", {
                "id": this.questionnaire.questionnaireAssigned.id,
                "num_questions": this.questionnaire.numberOfQuestion,
                "difficulty": this.level.toString(),
            })
                .then(response1=>{
                    this.evaluations = response1.data
                }).catch(error1=>console.log(error1))
            
            this.showSlides(this.slideIndex);
            
        },
        
        data(){
            return{
                user : store.state.user,
                questionnaire : {},
                idQuestionnaire : this.$route.params.id,
                slideIndex : 1,
                evaluations :[],
                level :1,
                point :0, 
                nbreQuestion : 0, 

                hours : 0,
                minutes : 0,  
                seconds : 0, 

                nbreTotalQuestion : 0, 
                numeroQuestion : 1, 
                correctAnswerLevel: [0, 0, 0],
                countLevel: [1, 0, 0], 
                result : 0,
                
                savedAnswers : [],
                answer:"",
                isFinished:false, 
                alreadyArchived : false, 
                showLoading : false
            }
        },
        mounted(){
            if(this.questionnaire && this.questionnaire.isArchived){
                this.alreadyArchived = true
                this.$router.push("/")
            }
            if (window.performance) {
                if (performance.navigation.type == 1) {
                    //alert( "This page is reloaded" );
                }else{
                    //alert( "This page is not reloaded" );
                }
            }
        },
        methods:{
            sendMailNotification() {
                console.log("sendMailNotification appelé");
                console.log("user_id : " + this.user.Id + " / questionnaire : " + this.idQuestionnaire);

                const payload = {
                    "user_id" : this.user.Id ,
                    "assignedQuestion_id" : this.idQuestionnaire
                }
                axios.post(data.baseUrl + "thotea/api/finished-test-notification/", payload)
                .then(response => {
                    console.log("Réponse de la notification : ", response);
                })
                .catch(error => {
                    console.error("Erreur lors de l'envoi de la notification", error);
                })

            },
            plusSlides(n) {
                this.showSlides(this.slideIndex += n);
            },
            currentSlide(n) {
                this.showSlides(this.slideIndex = n);
            },
            isAnswer(response){
                return this.answer.split("|||").includes(response)
            },
            checkResponse(response){
                if(this.answer=="")
                    this.answer = response
                else {
                    var answerTab = this.answer.split('|||')
                    let index = answerTab.findIndex(item=> item==response)
                    
                    if(index!=-1){
                        answerTab.splice(index, 1)
                        let string = ""
                        answerTab.forEach(item=>{
                            string += item+"|||"
                        })
                        this.answer = string.substring(0, string.length-3)
                    }
                    else
                        this.answer = this.answer+"|||"+response
                }
                
            },
            validAnswer(index, questionnaire_id){
                this.nbreQuestion++
                this.countLevel[this.level-1]++
                this.savedAnswers.push({
                    user_answer: this.answer,
                    user_id: this.user.Id,
                    questionnaire_id: this.questionnaire.id,
                    question_id: questionnaire_id,
                })
                const tabAnswer = this.answer.split("|||")
                const correctAnswer = this.evaluations[index].correctAnswer.split("|||")
                
                if(correctAnswer.length==tabAnswer.length){ 
                    let countRightAnswer = 0
                    tabAnswer.forEach(item=>{
                        if(correctAnswer.includes(item.trim())){
                            countRightAnswer++
                        }
                    })
                    
                    if(countRightAnswer==tabAnswer.length){
                        this.point++
                        this.correctAnswerLevel[this.level-1]++
                    }
                }
                if(this.nbreQuestion==3 && this.point==3){
                    this.updateLevel(+1)
                }
                else if(this.nbreQuestion==3 && this.point<3){
                    this.updateLevel(-1)
                }   
                this.plusSlides(1)
                if(this.numeroQuestion<this.nbreTotalQuestion){
                    this.numeroQuestion++
                }
                else{
                    this.getResult()
                }
                this.answer=""

            },
            
             updateLevel(n){
                if( (this.level+n)<=3 &&  (this.level+n)>=1 ){
                    this.level += n
                    //this.countLevel[this.level-1]++
                    this.changeEvaluation()
                }
                this.point = 0
                this.nbreQuestion = 0
                
            },
            async changeEvaluation(){
                this.evaluations = []
                await axios.post(data.baseUrl+"thotea/GetEvualation", {
                        "id": this.questionnaire.questionnaireAssigned.id,
                        "num_questions": this.questionnaire.numberOfQuestion,
                        "difficulty": this.level.toString(),
                    })
                        .then(response1=>{
                            this.evaluations = response1.data
                        }).catch(error1=>console.log(error1))
                   
                        this.showSlides(this.slideIndex);     
            },
            getResult(){
                const sumCorrectAnswer = this.correctAnswerLevel[0]+this.correctAnswerLevel[1]+this.correctAnswerLevel[2]
                console.log(sumCorrectAnswer, this.nbreTotalQuestion)
                this.result = (sumCorrectAnswer/this.nbreTotalQuestion)*100
                const timeRemaining = document.getElementById('timeRemaining').textContent.replace('TEMPS', '')
                this.showLoading = true
                const payload = {
                        name: this.questionnaire.questionnaireAssigned.Title,
                        results: this.result,
                        levelOne: this.correctAnswerLevel[0], 
                        levelTwo: this.correctAnswerLevel[1],
                        levelThree: this.correctAnswerLevel[2],
                        user: this.user.Id,
                        timeRemaining: timeRemaining,
                        countLvl1: this.countLevel[0]-1,
                        countLvl2: this.countLevel[1],
                        countLvl3: this.countLevel[2],
                        dateTest: moment(String(new Date())).format('YYYY-MM-DDThh:mm'),
                        assigned_questionnaire: this.questionnaire.id,
                }
                //console.log("Envoi des testes : ", payload)
                axios.post(data.baseUrl+"thotea/api/questiongrade/", payload)
                        .then(response1=>{
                            console.log("Le resultat apres envoi des payloads: ", response1)
                            if(response1.data.id){

                                axios.post(data.baseUrl+"thotea/createQuestionnaireHistoric/",this.savedAnswers)
                                    .then(resp=>{
                                        console.log(resp)
                                        axios.put(data.baseUrl+"thotea/api/assignquestionnaires/"+this.questionnaire.id+"/", {
                                            userID: this.user.Id,
                                            questionnaireAssigned: this.questionnaire.questionnaireAssigned.id,
                                            time: this.questionnaire.time,
                                            numberOfQuestion: this.questionnaire.numberOfQuestion,
                                            isArchived: true,
                                        }).then(response=>{
                                            if(response.data){
                                                this.isFinished=true; 
                                                toast.success("Vos réponses ont été enregistrées");
                                                console.log("Appel à sendMailNotification avant redirection");

                                            // Appel à la méthode pour envoyer la notification par email
                                                this.sendMailNotification(); 
                                                //this.$router.push('/result/'+response1.data.id)
                                                this.$router.push({ name: 'resultat', params: { id: response1.data.id }} )

                                            }
                                        }).catch(error=>console.log(error))
                                    }).catch(error=>console.log(error))
                               
                            }
                                
                        }).catch(error1=>console.log(error1))   
            },

            showSlides(n) {
                let i;
                let slides = document.getElementsByClassName("mySlides");
                let dots = document.getElementsByClassName("dot");
                if (n > slides.length) {this.slideIndex = 1}
                if (n < 1) {this.slideIndex = slides.length}
                for (i = 0; i < slides.length; i++) {
                    slides[i].style.display = "none";
                }
                for (i = 0; i < dots.length; i++) {
                    dots[i].className = dots[i].className.replace(" active", "");
                }
                slides[this.slideIndex-1].style.display = "block";
                dots[this.slideIndex-1].className += " active";
            }

        },
        beforeRouteLeave (to, from , next) {
            if(this.alreadyArchived){
                next()
            }
            else{
                if(this.isFinished==false ){
                
                const answer = window.confirm('Voulez-vous vraiment quitter le test ? Vos réponses ne seront pas enregistrés !')
                if (answer) {
                    next()
                } else {
                        next(false)
                    }
                }else{
                    next()
                }
            }
           
        }
        
                
      }
</script>
<style>
.textTitle{
        color: #fab833;
    }
    .cardTitle{
        background-color: white;
        border-radius: 4% / 20%; 
        /* border-radius: 2%; */
        /* min-height: 50px; */
    }
    .barProgress{
        
        border : 2px solid #fab833;
        background-color: rgba(250, 249, 247, 0.064)
    }
    .pbTest{
        
        background-color: #c89a1d;
    }
    .cardSlide{
        background-color: rgba(255, 255, 255, 0);
    }
    .contentBg{
        background-color: #9c4c77;
    }
    .bgOpac{
        background-color: rgba(255, 255, 255, 0.053);
    }
    .question{
        
        background-color: #a5815758;
        color: black;
    }
    .timer{
        background-color: darkgreen;
        border-radius: 15%;   
    }
    .responses{
        padding: 20px;

    }
    .textResponse{
        padding-left: 20px;
        padding-right: 20px;
        font-size: large;
        color:white; 
        border-color:white;
        border-radius: 5% / 100%;
    }
    .niveau{
        background-color: #a58057;
    }
    
    .info{
        padding-top: 2%;
        font-style: italic;
    }

    * {box-sizing:border-box}

        /* Slideshow container */
        .slideshow-container {
        /*max-width: 1000px;*/
        position: relative;
        margin: auto;
        }

        /* Hide the images by default */
        .mySlides {
        display: none;
        }

        /* Next & previous buttons */
        .prev, .next {
        cursor: pointer;
        position: absolute;
        top: 50%;
        width: auto;
        margin-top: -22px;
        padding: 16px;
        color: white;
        font-weight: bold;
        font-size: 18px;
        transition: 0.6s ease;
        border-radius: 0 3px 3px 0;
        user-select: none;
        }

        /* Position the "next button" to the right */
        .next {
        right: 0;
        border-radius: 3px 0 0 3px;
        }

        /* On hover, add a black background color with a little bit see-through */
        .prev:hover, .next:hover {
        background-color: rgba(0,0,0,0.8);
        }

        /* Caption text */
        .text {
        color: #f2f2f2;
        font-size: 15px;
        padding: 8px 12px;
        position: absolute;
        bottom: 8px;
        width: 100%;
        text-align: center;
        }

        /* Number text (1/3 etc) */
        .numbertext {
        color: #f2f2f2;
        font-size: 12px;
        padding: 8px 12px;
        position: absolute;
        top: 0;
        }

        /* The dots/bullets/indicators */
        .dot {
        cursor: pointer;
        height: 15px;
        width: 15px;
        margin: 0 2px;
        background-color: #bbb;
        border-radius: 50%;
        display: inline-block;
        transition: background-color 0.6s ease;
        }

        .active, .dot:hover {
        background-color: #717171;
        }

        /* Fading animation */
        .fade {
        animation-name: fade;
        animation-duration: 1.5s;
        }

        @keyframes fade {
        from {opacity: .4}
        to {opacity: 1}
        }

    
</style>
     