<template>
  <div class="popup">
    <div class="popup-inner" style="background-color: #1c3615;">
      <slot/>
      
      <div class="pl-5 pr-5">
        <div class="form-group">
          <label class="text-white">Changement de status </label>
          <select class="form-control fieldLogin" v-model="etat">
            <option v-for="status in statusOptions" :key="status" :value="status">
              {{ status }}
            </option>
          </select>
        </div>
        
        <div align="center">
          <button class="btn btn-secondary" @click="updateTask">Envoyer</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { updateTask } from '@/data/apiPythie';
import { useToast } from 'vue-toastification';

const toast = useToast();
export default {
  props: ['objet', 'type', 'user'],
  data() {
    return {
      etat: '',
      statusOptions: [ 'En cours' ,"À tester" ,  "Bloqué", 'Terminé']
    };
  },
  mounted() {
    // Pré-remplir les champs avec les données de l'objet transmis
    if (this.objet) {
      this.etat = this.objet.statut || 'Blank'; // Par défaut, "Blank" est sélectionné si aucun statut n'est défini
    }

  },
  methods: {
    

    async updateTask() {
      const idTache = this.objet.id;
      var payload = {
        "statut": this.etat,
      };

      console.log(payload , idTache);
      await updateTask(payload, idTache);
      toast.success("Modification statut reussie")
      this.$emit('close');
      setInterval(()=>{
          this.$router.go()
      }, 1500)
    }
  },



};
</script>
