<template>
    <div class="app" id="ness">
      <Header :user="user" page="requests" logo="ness"/>
      <div class="row">
        <back-button />
      <br>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-3"></div>
            <h2 class="cardTitle p-2 pl-5 pr-5 col-md-5" style="font-weight: bold;">Nouvelles demandes d'ajout</h2>
            <div class="col-md-1"></div>
            <div class="col-md-2">
              <input class="mt-2 form-control fieldLogin pl-2 pr-2 icon-search" placeholder="Recherche site" v-model="search"/>
            </div>
        </div>
        <br>
        <div class=" pl-5 pr-5 pt-5">
        <div class="row " style="background-color: rgba(255, 255, 255, 0);border: 3px solid white;border-radius: 4% / 14%;max-height: 60vh;overflow-y: auto;">
            <table class="table border-collapse p-4" >
                <thead class="textTitle" style="background-color: white;border-radius: 10%; font-weight: bold;font-size: larger;">
                    <th>Type de réseau</th>
                    <th>Site</th> 
                    <th>Date de soumission</th>
                    <th>Statut</th>
                    <th>Détail</th>
                </thead>
                <tbody class="text-white">
                    <tr v-for="reseau in filteredNetwork()" :key="reseau.Id">
                        <td>{{ reseau.libelle }}</td>
                        <td>{{ reseau.domain }}</td>
                        <td>{{ reseau.date_soumission.substring(0, 10) }}</td>
                        <td>{{ reseau.validation==null? 'En attente de validation' : reseau.validation }}</td>
                        <td><button class="btn"  @click="showDetails(reseau)"><i class="fa-solid fa-angles-right" style="color: white;"></i></button></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <NetworkDetails :showDetail="showDetail" :network="selectedNetwork" v-if="showDetail" @close="showDetail=false">
          <button type="button" class="btn  float-right text-white" @click="showDetail=false" >x</button>
          <h3 class="textTitle">SITE : {{ selectedNetwork.domain }}</h3>
        </NetworkDetails>
        </div>
    </div>
    <Chatroom/>
  </template>
  
  <script>
  import Header from '@/components/shared/Header.vue';
  import BackButton from '@/components/shared/BackButton.vue';
  import NetworkDetails from '../NetworkDetails.vue'
  import {getWaitingNetworks} from '@/data/apiNess';
  import store from '@/store';
import Chatroom from '@/components/chatroom/Chatroom.vue';

  export default {
    name: "statutDemande",
    props: {},
    components: {
    BackButton,
    Header, NetworkDetails,
    Chatroom
},
  
    data() {
      return {
        user : store.state.user, 
        reseaux : [], 
        search : '', 
        showDetail : false, 
        selectedNetwork : {},
        request : []
      };
    },
    async mounted(){
        // this.reseaux = await getReseaux()
        this.reseaux = await getWaitingNetworks()
        this.request = this.reseaux.filter(item=>{
          return item.validation==null
        })
    },
    methods:{
      filteredNetwork(){
        if(this.search!=''){
          return this.request.filter(item=>{
            return item.domain.toLowerCase().includes(this.search.toLowerCase())
          })
        }else return this.request
      },
      showDetails(network){
        this.selectedNetwork = network
        this.showDetail = !this.showDetail
      }
    }
  
  };
  </script>
  <style>
  ::-ms-input-placeholder {
    color: white;
  }
</style>