<template>

    <Header :user="user" logo="themis" page="entreprise-admin" />
    <div class="row">
        <back-button />

    </div>

    <div class="pl-5 pr-5 pt-3">
        <div class="row " style="border: 5px solid white;border-radius: 4% / 14%;height: 60vh; margin: 20px;">
            <div class="col-md-4 p-5"
                style="height: 100%;overflow-y: auto;background-color: white;border-top-left-radius: 10% 13%; border-bottom-left-radius: 10% 13%;">
                <div style="height: 100%;overflow-y: auto;">
                    <ul class="list-group">
                        <li class="m-3 list-item-group p-2 " :class="category === company.id ? 'active ' : ''"
                            v-for="company in titleCompanies" :key="company" @click="changeCategory(company.id)"
                            style="cursor: pointer;list-style: none;font-size: large;">
                            <span>{{ company.titre }}</span><button class="btn float-right" v-show="showCollab && user.profile.user_rights_id > 3"
                                @click="removeCateg(company.id)"><i class="fa-solid fa-trash"></i></button>
                        </li>
                    </ul>
                </div>
                <button v-show="showCollab && user.profile.user_rights_id > 3 " class="btn btn-primary" @click="showPopupCateg = !showPopupCateg">Ajout
                    catégorie</button>
            </div>
            <div class="col-md-8 p-3 liste" style="max-height: 60vh;overflow-y:auto ">
                <button v-show="showCollab && user.profile.user_rights_id > 3" class="btn round-circle  btn-primary float-right"
                    @click="showPopupPdf = !showPopupPdf"><i class="fa-solid fa-plus"></i></button>
                <p v-show="showCollab && user.profile.user_rights_id > 3" class=" text-white float-right mt-2 mr-2">Ajouter un PDF</p>
                <hr v-show="showCollab">
                <br v-show="showCollab">
                <div class="liste p-2" style="border-radius: 1%/2%;max-height:60vh ; overflow-y: auto;">
                    <div class="row pl-5 pr-5 pt-3 pb-2">
                        <input class=" form-control fieldLogin" placeholder="Recherche" v-model="search"
                            style="border: 1px solid rgba(255, 255, 255, 0);" />
                    </div>
                    <ul class="list-group">
                        <li class="list-group-item m-4 text-white fieldLogin" v-for="file in filteredFile()"
                            :key="file.id"
                            style="background-color: rgba(255, 255, 255, 0);cursor: pointer;border: 1px solid rgba(255, 255, 255, 0.25);"
                            @click="showPopupPdfRead(file)">
                            {{ file.titre }}
                        </li>
                    </ul>

                </div>
            </div>
            <ReadPdf v-if="readFile" @close="closePopup" :file="file">
                <button type="button" class="btn  float-right text-white" @click="closePopup()">x</button>
                <br>
            </ReadPdf>
        </div>
    </div>
    <AddPDF :categories="titleCompanies" v-if="showPopupPdf" @close="closePopup">
        <button type="button" class="btn  float-right text-white" @click="showPopupPdf = false">x</button>
        <h2 class="textTitle">Ajout fichier</h2>
        <hr>
    </AddPDF>
    <AddCategory :categories="titleCompanies" v-if="showPopupCateg" @close="closePopup"
        @categoryAdded="updateCategoryList">
        <button type="button" class="btn  float-right text-white" @click="showPopupCateg = false">x</button>
        <h2 class="textTitle">Ajout catégorie</h2>
        <hr>
    </AddCategory>
    <Chatroom />
</template>
<script>
import Header from '@/components/shared/Header.vue';
import BackButton from '@/components/shared/BackButton.vue';
import store from '@/store';
import { getTitleCompany, getPdfEntreprise, deleteCompany } from '@/data/apiThemis';
import AddPDF from '../popups/AddPDF.vue';
import ReadPdf from '../popups/ReadPdf.vue';
import Chatroom from '@/components/chatroom/Chatroom.vue';
import AddCategory from '../popups/AddCategory.vue';
import { useToast } from 'vue-toastification';
const toast = useToast()
export default {
    name: 'ThemisCompany',
    components: { Header, AddPDF, ReadPdf, BackButton, Chatroom, AddCategory },
    data() {
        return {
            user: store.state.user,
            showCollab: store.state.showCollab,
            titleCompanies: [],
            showPopupPdf: false,
            showPopupCateg: false,
            category: 1,
            allFiles: [],
            files: [],
            file: {},
            readFile: false,
            search: ''
        }
    },
    async mounted() {
        this.allFiles = await getPdfEntreprise()
        this.titleCompanies = await getTitleCompany()
        if (this.titleCompanies.length > 0) {
            this.category = this.titleCompanies[0].id
            this.files = this.allFiles.filter(item => {
                return item.categories == this.category
            })
        }

    },
    methods: {
        filteredFile() {
            if (this.search != '') {
                return this.files.filter(item => {
                    return item.titre.toLowerCase().includes(this.search.toLowerCase())
                })
            }
            return this.files
        },
        closePopup() {
            this.showPopupPdf = false
            this.readFile = false
            this.showPopupCateg = false
        },
        changeCategory(idCompany) {
            this.category = idCompany
            this.files = this.allFiles.filter(item => {
                return item.categories == this.category
            })

        },
        async removeCateg(id) {

            await deleteCompany(id)
            toast.success("Catégorie supprimée")
            // Mise à jour de la liste locale des catégories après suppression
            this.titleCompanies = this.titleCompanies.filter(company => company.id !== id);

           if (this.category === id) {
                // Sélectionner la première catégorie restante ou null si aucune catégorie
                this.category = this.titleCompanies.length > 0 ? this.titleCompanies[0].id : null;
                this.files = this.allFiles.filter(item => item.categories === this.category);
            }

        },
        showPopupPdfRead(file) {
            this.file = file
            this.readFile = true
        },
        async updateCategoryList() {
            // Recharger la liste des catégories
            this.titleCompanies = await getTitleCompany();
        }
    }
}
</script>
