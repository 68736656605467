<template>
    <div id="thotea">
        <Header :user="user" page="candidat" logo="thotea" />
        <!-- <button class="thoteaBg btn rounded-circle float-left m-2" @click="back()"><i class="fas fa-arrow-left" color="white"></i></button> -->
        <back-button />
        <br>
        <div class=" p-3 ">

            <div class="row  pt-4" v-if="candidate">

                <div class="col-md-12 " align="center">
                    <h1 class="col-md-5 textTitle justify-content-center">Assigner Questionnaire à {{ candidate.Username
                        }}</h1>
                    <div class="p-3">
                        <form>

                            <div class="row">
                                <div class="col-md-4">

                                    <div class="card p-2" style="background-color: rgba(255, 255, 255, 0);">
                                        <h2 class="text-white">Tous les questionnaires</h2>
                                        <br>
                                        <input class="form control input mb-3 mr-3" v-model="searchQuestionnaire" />
                                        <div class="row" style="max-height: 45vh;overflow-y: scroll;">
                                            <div class="col-md-4 p2" v-for="theme in filteredTheme()" :key="theme.id">
                                                <p :class="!questionnaireAssigned.includes(theme.id) ? 'btn btn-outline-info' : 'btn btn-info'"
                                                    style="width: 100%;" @click="checkTheme(theme.id, theme.Title)">
                                                    {{ theme.Title }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-1"></div>
                                <div class="col-md-7">
                                    <br>
                                    <p class="text-white">Cliquer sur le(s) questionnaire(s) à assigner</p>
                                    <div class="card fieldLogin p-5 mb-1" style="min-height: 20vh;">

                                        <div class="row " v-if="questionnaireAssigned.length > 0">
                                            <span class="activeQuestionnaire p-2"
                                                v-for="(questionnaire, index) in questionnaireAssignedName"
                                                :key="questionnaire.id">{{ questionnaire }} <i
                                                    class="fa-regular fa-circle-xmark"
                                                    style="color: red;margin-top: -5px;"
                                                    @click="checkTheme(questionnaireAssigned[index])"></i></span>
                                        </div>
                                    </div>
                                    <!-- <div class="form-group">
                                        <label class="float-left textTitle" for="categorie">Selectionnez une catégorie *</label>
                                        <select class="form-control fieldLogin " id="recruteur" v-model="categorie" @change = "onchangeCategory" >
                                            <option v-for="categorie in categories" :key="categorie.id" :value="categorie.id" >{{categorie.title}} </option>
                                        </select>
                                    </div> -->
                                    <div class="form-group" :hidden="categorie == ''">
                                        <label class="text-white" for="questionnaire">Choisir questionnaire *</label>
                                        <br>
                                        <div class="row" v-if="themeFiltered.length > 0">
                                            <div class="col-md-3" v-for="theme in themeFiltered" :key="theme.id">
                                                <p :class="!questionnaireAssigned.includes(theme.id) ? 'btn btn-outline-info' : 'btn btn-info'"
                                                    @click="checkTheme(theme.id)">{{ theme.Title }}</p>
                                            </div>
                                        </div>
                                        <p v-else>Aucun questionnaire enregistré dans cette categorie</p>
                                    </div>
                                    <div class="form-group">
                                        <label class="text-white" for="NumberOfQuestion">Nombre de questions</label>
                                        <input class="form-control fieldLogin" type="number" v-model="nbreQuestion" />
                                    </div>
                                    <div class="form-group">
                                        <label class="text-white" for="time">Temps d'évaluation (en minutes)</label>
                                        <input class="form-control fieldLogin" type="number" v-model="time" />
                                    </div>
                                    <button type="button" class="btn btn-block btn-warning text-white" @click="assigner"
                                        :disabled="questionnaireAssigned.length == 0 || isAssigning">
                                        Assigner
                                    </button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>


            </div>
        </div>
    </div>
    <Chatroom />
</template>
<script setup>
import Chatroom from '@/components/chatroom/Chatroom.vue';
</script>
<script>
import Header from '@/components/shared/Header.vue';
import axios from 'axios';
import data from '@/data/data';
import { useToast } from "vue-toastification";
import store from '@/store';
import functions from '@/data/function';
import BackButton from "@/components/shared/BackButton.vue";

const toast = useToast()
export default {
    name: 'listQuestionnaire',
    props: {

    },
    components: { Header, BackButton },

    data() {
        return {
            user: store.state.user,
            idCandidate: this.$route.params.id,
            candidate: undefined,
            themes: [], themeFiltered: [],
            categorie: "",
            questionnaireAssigned: [],
            questionnaireAssignedName: [],
            categories: [],
            time: 10,
            nbreQuestion: 10,
            searchQuestionnaire: '',
            isAssigning: false,  // Indicateur de l'état du bouton
        }
    },
    mounted() {

        if (this.user.profile.user_rights_id <= 2) this.$router.push("/")
        axios.get(data.baseUrl + "thotea/api/categories")
            .then(response => {
                this.categories = response.data

            }).catch(error => console.log(error))
        axios.get(data.baseUrl + "thotea/api/getQuestionnaireList/?linkedTheme=" + this.idTheme)
            .then(response => {
                this.countAllQuestion = response.data.length
                this.allQuestions = response.data
                this.questions = response.data.filter(item => {
                    return item.difficulty == this.niveau
                })
                // console.log(this.questions)
                this.allQuestionslevel = this.questions
            }).catch(error => console.log(error))
        axios.get(data.baseUrl + "thotea/api/questionnaires")
            .then(response => {
                this.themes = response.data
                this.themeFiltered = this.themes
            }).catch(error => console.log(error))
        axios.post(data.baseUrl + "authentication/api/user/", { "id": this.idCandidate })
            .then(response => {
                this.candidate = response.data;
            }).catch(error => console.error(error))

    },
    methods: {
        onchangeCategory() {
            if (this.categorie != "") {
                this.themeFiltered = this.themes.filter(item => {
                    return item.category == parseInt(this.categorie)
                })
            }
            else this.themeFiltered = this.themes
        },
        checkTheme(id, name) {
            let index = this.questionnaireAssigned.findIndex(item => item == id)
            if (index == -1) {
                this.questionnaireAssigned.push(id)
                this.questionnaireAssignedName.push(name)
            }
            else {
                this.questionnaireAssigned.splice(index, 1)
                this.questionnaireAssignedName.splice(index, 1)
            }
        },
        async assigner() {
            // Désactive le bouton dès que l'assignation commence
            this.isAssigning = true;

            for (let index = 0; index < this.questionnaireAssigned.length; index++) {
                var item = this.questionnaireAssigned[index];
                var payload = {
                    "time": this.time,
                    "numberOfQuestion": this.nbreQuestion,
                    "userID": parseInt(this.idCandidate),
                    "questionnaireAssigned": item,
                    "isArchived": false
                };

                try {
                    // Vérifie si le questionnaire a suffisamment de questions
                    const response = await axios.get(data.baseUrl + "thotea/api/getQuestionnaireList/?questionnaire_id=" + item);
                    if (response.data.length >= this.nbreQuestion) {
                        // Envoie l'assignation
                        const assignResponse = await axios.post(data.baseUrl + "thotea/api/assignquestionnaires/", payload);
                        if (assignResponse.data.id) {
                            toast.success("Assignation de questionnaire reussie");
                            this.$router.push("/candidates");
                        } else {
                            toast.error(assignResponse.data);
                        }
                    } else {
                        toast.error('Nombre de questions dans "' + this.questionnaireAssignedName[index] + '" insuffisant');
                    }
                } catch (error) {
                    console.error(error);
                    toast.error("Erreur lors de l'assignation du questionnaire");
                }
            }

            // Réactive le bouton après l'assignation
            this.isAssigning = false;
        }
        ,
        back() {
            functions.goBack()
        },
        filteredTheme() {
            if (this.searchQuestionnaire != '') {
                return this.themes.filter(item => {
                    return item.Title.toLowerCase().includes(this.searchQuestionnaire.toLowerCase())
                })
            }
            else return this.themes
        }
    },


}
</script>
<style>
/* ::-webkit-scrollbar {
  -webkit-appearance: initial;
  width: 15px;
} */
::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: rgba(200, 200, 200, .5);
    box-shadow: 0 0 1px rgba(10, 10, 10, 0.9);
}

::-webkit-scrollbar-track {
    background-color: rgba(100, 100, 100, .5);
    border-radius: 20px;
}

.transparent {
    background-color: #ffffff00;
    color: white
}

.activeQuestionnaire {
    background-color: white;
    margin: 10px;
    color: black;
}
</style>