<template>
  <div class="slide-panel" :class="{ open: true }" @click="closePanel">
    <div class="slide-content" @click.stop>
      <button class="close-btn" @click="closePanel">x</button>
      <h3>Commentaires : {{ tache }}</h3>

      <ul class="comments-list" v-if="comments.length > 0">
        <li class="comment-item" v-for="(comment, index) in comments" :key="index">
          <p>
            <strong class="author-name">{{ comment.author }}:</strong>
            {{ comment.comment }}
          </p>
          <small class="timestamp">Posté le : {{ formatTimestamp(comment.timestamp) }}</small>
        </li>
      </ul>
      <p v-else class="no-comments">Aucun commentaire pour le moment.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "commentTask",
  props: ["objet", "type"],
  data() {
    return {
      comments: [],
      tache: '',
    };
  },
  mounted() {
    this.commentsList();
  },
  methods: {
    closePanel() {
      this.$emit("close");
    },
    commentsList() {

      this.comments = this.filterComments(this.objet.commentaires || []);
      this.tache = this.objet.tache;
    },
    filterComments(comments) {
      return comments.filter(comment => {

        const hasValidAuthorAndComment = comment.author?.trim() && comment.comment?.trim();
        const hasValidTimestamp = !isNaN(new Date(comment.timestamp).getTime());
        return hasValidAuthorAndComment && hasValidTimestamp;
      });
    },
    formatTimestamp(timestamp) {
      const date = new Date(timestamp);
      return date.toLocaleString();
    },
  },
};
</script>



<style>
.slide-panel {
  position: fixed;
  top: 0;
  right: -40%;
  height: 100%;
  width: 40%;
  background: rgb(28, 54, 21);
  transition: right 0.3s ease-in-out;
  z-index: 1000;
  overflow-y: auto;
  color: white;
  border-left: 1px solid #fab83380;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
  font-family: Arial, sans-serif;
}

.slide-panel.open {
  right: 0;
}


.slide-content {
  padding: 20px;
}


button.close-btn {
  position: absolute;
  top: 3px;
  left: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: white;
  padding: 5px;
  border-radius: 50%;
  transition: background 0.3s ease;
}


.comments-list {
  list-style: none;
  padding: 0;
  margin: 20px 0;
}

.comment-item {
  margin-bottom: 15px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
}

.author-name {
  font-weight: bold;
  color: #fab833;
  display: inline-block;
  text-align: left;
  margin-right: 10px;
}

.comment-item p {
  margin: 0;
  line-height: 1.5;
  font-size: 16px;
  color: #e1e1e1;
  text-align: left;
}


textarea {
  width: 100%;
  height: 150px;
  padding: 10px;
  margin-top: 20px;
  border-radius: 5px;
  border: 1px solid white;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  font-size: 14px;
  resize: none;
}

textarea:focus {
  outline: none;
  border: 1px solid #fab833;
  background: rgba(255, 255, 255, 0.15);
}


button.send-btn {
  margin-top: 10px;
  background-color: #fab833;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button.send-btn:hover {
  background-color: #f3a500;
}

.timestamp {
  display: block;
  text-align: right;
  margin-top: 5px;
  color: #ccc;
  font-size: 12px;
}

.no-comments {
  text-align: center;
  color: #ccc;
  font-style: italic;
  margin-top: 20px;
}
</style>