import data from "@/data/data"
import axios from 'axios'

import { useToast } from "vue-toastification";
const toast = useToast()
export async function getSuivi (idUser){
    try{
        const response = await axios.get(data.baseUrl + "pythie/suiviactivit/?userId="+idUser)
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
      console.log(error)
    } 
}


export async function deleteSuivi (idSuivi){
    try{
        const response = await axios.patch(data.baseUrl + "pythie/suiviactivit/"+idSuivi+"/archive/")
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
      console.log(error)
    } 
}


export async function newTask (payload){
    try{
        const response = await axios.post(data.baseUrl + "pythie/suiviactivit/", payload)
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
      console.log(error)
    } 
}

export async function updateTask(payload , idTache){
    try{
        const response = await axios.patch(data.baseUrl + "pythie/suivi-activit/"+idTache+"/update/", payload)
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
      console.log(error)
    } 
}


export async function getMissions(idUser){
    try{
        const response = await axios.get(data.baseUrl + "pythie/mission/?userId="+idUser)
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
        console.log(error)
    } 
}
export async function newMission(payload){
    try{
          const response = await axios.post(data.baseUrl + "pythie/mission/", payload)
          if (!JSON.stringify(response.data).includes("Error"))
              return response.data
          else {
              toast.error(JSON.stringify(response.data))
          }
    }catch(error) {
      console.log(error)
    } 
}

export async function getThemeFormation(){
    try{
        const response = await axios.get(data.baseUrl + "pythie/formationtheme/")
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
        console.log(error)
    } 
}
export async function getTypeFormation(){
    try{
        const response = await axios.get(data.baseUrl + "pythie/formationtype/")
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
        console.log(error)
    } 
}
export async function getDifficultyFormation(){
    try{
        const response = await axios.get(data.baseUrl + "pythie/formationdifficulte/")
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
        console.log(error)
    } 
}

export async function getFormations(){
    try{
        const response = await axios.get(data.baseUrl + "pythie/formation/")
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
        console.log(error)
    } 
}
export async function getFormationUsers(){
    try{
        const response = await axios.get(data.baseUrl + "pythie/formationutilisateur/")
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
        console.log(error)
    } 
}
export async function getFormationUser(id){
    try{
        const response = await axios.get(data.baseUrl + "pythie/formationutilisateur/?userID="+id)
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
        console.log(error)
    } 
}
export async function getFormationStatus(){
    try{
        const response = await axios.get(data.baseUrl + "pythie/formationstatus/")
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
        console.log(error)
    } 
}
export async function assignFormation(payload){
    try{
        const response = await axios.post(data.baseUrl + "pythie/postformationutilisateur/", payload)
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
        console.log(error)
    } 
}

export async function advising(){
    try{
        const response = await axios.post(data.baseUrl + "pythie/formationavis/")
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
        console.log(error)
    } 
}


export async function rapporting(){
    try{
        const response = await axios.post(data.baseUrl + "pythie/formationrapport/")
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
        console.log(error)
    } 
}


export async function departement(idDepart){
    try{
        const response = await axios.get(data.baseUrl + "authentication/api/api/department/"+idDepart+"/")
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
        console.log(error)
    } 
}

export async function getJobInstance(idJob){
    try{
        const response = await axios.get(data.baseUrl + "authentication/api/api/jobs/"+idJob+"/")
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
        console.log(error)
    } 
}



export async function getUserById(id){
    try{
        const response = await axios.post(data.baseUrl + "authentication/api/user/", {"id" : id})
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
        console.log(error)
    } 
}


export async function sendMail(payload){
    try{
        const response = await axios.post(data.baseUrl + "pythie/sendMail/", payload)
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
        console.log(error)
    } 
}

//Signaler tache -- changer signaler en true
export async function signalTask(tache){
    try{
        tache.signaler = true
        const response = await axios.put(data.baseUrl + "pythie/suiviactivit/"+tache.id+"/", tache)
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
        console.log(error)
    } 
}
//Signaler fiche mission 

export async function signalMission(mission){
    try{
        mission.signaler = true
        const response = await axios.put(data.baseUrl + "pythie/mission/"+mission.id+"/", mission)
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
        console.log(error)
    } 
}
