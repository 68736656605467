<template>
    <div class="container-fluid accueilSettings"><br><br>
        <BackButton/>
        <div class="row justify-content-center align-items-center cardLogin"> 
            <div class="col-12 col-md-8">
                <div class="card formulaire text-white">
                    <div class="row p-5">
                        <div class="col-md-3 mt-5 p-3">
                            <ul class="list-group mt-5" style="cursor: pointer; list-style-type: none;">
                                <!-- Affichage du menu "Mon compte" si user_right_id < 3 -->
                                <li
                                    class="list-item-group p-3" 
                                    :class="divMyAccount ? 'active' : ''" 
                                    @click="divMyAccount = true; divUsers = false">
                                    Mon compte
                                </li>

                                <!-- Affichage de "Utilisateurs" si user_right_id >= 3 -->
                                <li v-if="user.profile.user_rights_id >= 3"
                                    class="list-item-group p-3" 
                                    :class="divUsers ? 'active' : ''" 
                                    @click="divMyAccount = false; divUsers = true">
                                    Utilisateurs
                                </li>
                            </ul>
                        </div>

                        <div class="col-md-9">
                            <!-- Affichage de la section "Mon compte" si user_right_id < 3 -->
                            <div v-if="divMyAccount" class="pl-5 pr-5">
                                <h3>Changer mon mot de passe</h3>
                                <br>
                                <hr>
                                <div class="form-group">
                                    <label class="text-white float-left">Mot de passe actuel</label>
                                    <input type="password" class="form-control fieldLogin" v-model="mypassword"/>
                                </div>
                                <div class="form-group">
                                    <label class="text-white float-left">Nouveau mot de passe</label>
                                    <input type="password" class="form-control fieldLogin" v-model="newPassword"/>
                                </div>
                                <div class="form-group">
                                    <label class="text-white float-left">Saisir le mot de passe à nouveau</label>
                                    <input type="password" class="form-control fieldLogin" v-model="newPassword2"/>
                                </div>
                                <br>
                                <button align="center" class="btn col-4 btn-primary" 
                                        :disabled="mypassword === '' || newPassword === '' || newPassword2 === '' || newPassword !== newPassword2" 
                                        @click="changePassword()"> Valider
                                </button>
                            </div>

                            <!-- Affichage de la section "Utilisateurs" si user_right_id >= 3 -->
                            <div v-if="divUsers && user.profile.user_rights_id >= 3" class="pl-5 pr-5">
                                <h3>Groupe des utilisateurs </h3>
                                <br>
                                <hr>
                                <input class="form-control fieldLogin" v-model="search" placeholder="Rechercher un utilisateur"/>
                                <hr>
                                <div class="divUser pt-2" style="height: 50vh; overflow-y: auto;">
                                    <div class="pt-2" v-for="userRight in filteredUsersByRights" :key="userRight.id">
                                        <div >
                                            <strong class="text-left">{{ userRight.description }}</strong>
                                            <div class="row p-2" v-for="user in userRight.users" :key="user.id">
                                                <div class="col-md-5 text-left">
                                                    <small>{{ user.last_name }} {{ user.first_name }}</small>
                                                </div>
                                                <div class="col-md-6">
                                                    <small>{{ user.email }}</small>
                                                </div>
                                                <div class="col-md-1" >
                                                    <a  @click="selectedUser = user; userId= user.id ; groupUser = user.groups; right = user.extension[0]?.user_rights_id || ''; settingUser = true">
                                                        <i class="fa-solid fa-pen-to-square"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="text-center" v-if="userRight.users.length === 0">
                                                <small class="text-center">Aucun résultat</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Chatroom/>
    </div>

    <!-- Popup de réglage utilisateur -->
    <SettingUser :selectedUser="selectedUser" :userId="userId" :groupUser="groupUser" :right="right" :userRights="this.user.profile.user_rights_id" v-if="settingUser" @close="settingUser = false">
        <button type="button" class="btn float-right text-white" @click="settingUser = false">x</button>
    </SettingUser>
</template>

<script>
import Chatroom from '@/components/chatroom/Chatroom.vue';
import store from '@/store';
import BackButton from '@/components/shared/BackButton.vue';
import SettingUser from '@/views/Accueil/popup/SettingUser.vue';
import { getAllUsers, userRights } from '@/data/apiNess';
import { changePassword } from '@/data/apiUser';
import { useToast } from 'vue-toastification';

const toast = useToast();

export default {
    name: 'accueilPage',
    data() {
        return {
            user: store.state.user,
            mypassword: '', 
            newPassword: '', 
            newPassword2: '', 
            search: '',
            allusers: [], 
            divMyAccount: true,
            divUsers: false,
            settingUser: false,
            selectedUser: null,
            groupUser: [], 
            userRights: [], 
            right: 0,
            userId : 0 , 
        };
    },
    async mounted() {
        if (this.user) {
            if (this.user.id === -1) {
                this.$router.push('/login');
            }
            this.allusers = await getAllUsers();
            this.userRights = await userRights();
            
            // Si l'utilisateur a un droit inférieur à 3, on montre la section "Mon compte" par défaut
            if (this.user.profile.user_rights_id < 3) {
                this.divMyAccount = true;
            }
        } else {
            this.$router.push('/login');
        }

        console.log("User info"+JSON.stringify(this.user));
    },
    methods: {
        logout() {
            this.$store.dispatch('logout')
                .then(response => {
                    console.log(response);
                    this.$router.push('/login');
                }).catch(error => {
                    console.log(error);
                });
        },
        async changePassword() {
            if(this.newPassword != this.newPassword2){
                toast.error('La confirmation de mot de passe est incorrecte');
            }else {
                await changePassword(this.user.Id, this.mypassword, this.newPassword);
                //toast.success(result.message);
            }
        }
    },
    computed: {
        usersByRights() {
            return this.userRights.map(right => {
                return {
                    ...right,
                    users: this.allusers.filter(user => user.extension[0] ? user.extension[0].user_rights_id === right.id : false)
                };
            });
        },
        filteredUsersByRights() {
            return this.userRights.map(right => {
                const filteredUsers = this.allusers.filter(user => {
                    return (user.extension[0] ? user.extension[0].user_rights_id === right.id : false) &&
                        (user.last_name.toLowerCase().includes(this.search.toLowerCase()) || 
                         user.first_name.toLowerCase().includes(this.search.toLowerCase()));
                });
                return {
                    ...right,
                    users: filteredUsers
                };
            });
        }
    },
    components: { 
        Chatroom, 
        BackButton, 
        SettingUser 
    }
}
</script>

<style>
.accueilSettings {
    background-image: url('@/assets/login_page/background_home.jpg');
   background-image: url('@/assets/login_page/background_home.jpg');
    background-repeat: no-repeat;
    background-size: cover;  /* L'image couvre tout l'élément */
    background-position: center; /* Centrer l'image */
    min-height: 100vh;
    overflow-y: auto;
    padding-top: 2rem;
    padding-bottom: 2rem;

}

.card-header {
    background-color: #237eaf;
    border-radius: 2% / 22%;
    max-height: 10vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card-header img {
    float: left;
}

.card-header .float-right {
    display: flex;
    align-items: center;
}

.card-header .float-right img {
    margin-left: 0.5rem;
}

.content-area {
    margin: 0 auto;
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: auto;
}

.branch-img {
    width: 100%;
    max-width: 200px;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

@media (max-width: 768px) {
    .card-header {
        flex-direction: column;
        align-items: flex-start;
    }

    .content-area {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .branch-img {
        display: none;
    }
}

@media (max-width: 480px) {
    .content-area {
        flex-direction: column;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    .card-header {
        height: auto;
    }

    .card-header .float-right {
        margin-top: 0.5rem;
    }
}

::-webkit-scrollbar {
    width: 2px;
    margin-left: 2px;
    background-color: rgba(255, 255, 255, 0.158); /* couleur du "pouce" de la scrollbar */
}
</style>
