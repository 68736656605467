<template>
    <div id="ness">
        <Header :user="user" logo="ness"/>
        <div class="container" style="margin-top: 8%;">
            <div v-show="showCollab && user.profile.user_rights_id > 2" class="alert alert-warning alert-dismissible fade show" role="alert" style="margin-bottom: 50px">
                <strong>Ness administration : </strong>Vous êtes connecté en tant que {{ getUserRole() }}        
            </div>
            <div class="dashboard" v-if="!showCollab" >
                <router-link to="/ness/search">
                    <Case :icon="require('@/assets/ness/recherche_icon.png')"   title="Recherche" description="Recherche candidat" class="search" :user="user"/>
                </router-link >
                <router-link to="/ness/histories">
                    <Case :icon="require('@/assets/ness/historique_icon.png')" title="Historiques" description="Historiques des recherches" :user="user" class="historique"/>
                </router-link>
                <router-link to="/ness/networks">
                    <Case :icon="require('@/assets/ness/reseaux_icon.png')" title="Réseaux" description="Les réseaux"  :user="user" class="network"/> 
                </router-link>
            </div>
            <div class="dashboard" v-else >
                
                <router-link to="/ness/requests">
                    <Case :icon="require('@/assets/ness/recherche_icon.png')"   title="Nouvelles demandes " :description="'Notification ' +count " class="search" :user="user"/>

                </router-link>
                <router-link to="/ness/histories">
                    <Case :icon="require('@/assets/ness/historique_icon.png')"  title="Utilisateur" description="Gestion des utilisateurs" :user="user" class="historique"/>
                </router-link>
                <router-link to="/ness/gest-networks">
                    <Case :icon="require('@/assets/ness/reseaux_icon.png')"  title="Réseaux" description="Gestion de réseaux"  :user="user" class="network"/>

                </router-link>
            </div>
        </div>
    </div>
    <Chatroom/>
</template>

<script setup>
import Header from '@/components/shared/Header.vue';
import Case from '@/components/ness/Case.vue';
</script>

<script>
import store from '@/store';
import router from '@/router';
import { getWaitingNetworks} from '@/data/apiNess';
import Chatroom from '@/components/chatroom/Chatroom.vue';
 export default {
        name: 'DashboardView',
        data(){
            return{
                user: store.state.user,
                showCollab:store.state.showCollab, 
                reseaux : [], 
                count : 0
            }
        },
        methods: {
            redirectToTest(){
                router.push({ name: 'test', params: { id: this.user.id } })
            },
            getUserRole() {
                switch (this.user.profile.user_rights_id) {
                    case 3:
                        return "Gestionnaire";
                    case 4:
                        return "Administrateur";
                    case 5:
                        return "SuperAdmin";
                    default:
                        return "Utilisateur inconnu";
                }
            }
        },
        async mounted(){
            this.reseaux = await getWaitingNetworks()
            this.reseaux.map(item=>{
                if(item.validation==null){
                    this.count ++
                }
            })
            // this.count = this.reseaux.length
        },
    }
</script>
<style>
.content{
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80%;
}
.dashboard{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: space-between;
    gap: 100px;
    flex-wrap: wrap;
}
.historique{
    position: relative;
    top: 150px;
}
/* .profil{
    position: relative;
    top: 50px; 
} */
.network{
    position: relative;
    top: 0px; 
}
.search{
    position: relative;
    top: 0px; 
} 
@media (max-width: 768px) {
    .historique {
        top: 50px; 
    }
}

@media (max-width: 730px) {
    .historique {
        top: 30px;
    }
}

</style>