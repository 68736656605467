<template>
    <div class="app" id="themis">
      <Header :user="user" page="users" logo="themis"/>
      <div class="row">
        <back-button />
        <br>
      </div>
        <div class="row ">
            <div class="col-4"></div>
            <i class=" col-1 fa-solid fa-magnifying-glass mt-1" style="color: white;font-size: xx-large;"></i>
            <input class="col-3 form-control fieldLogin" placeholder="Prenom, Nom ..." v-model="search" style=""/>
            
        </div>
        <div class="row pl-5 pr-5 pt-5" style="max-height: 100vh; overflow-y: auto;">
            <div class=" col-md-4" v-for="user in filteredUsers()" :key="user.id" align="center">
                    <div class="row   p-4 m-1"  style="background-color: #ffffff33; " >

                        <div class="col-12" style="cursor: pointer;">
                            <img  src="@/assets/ness/Profil_photo.png" />
                            <h3 class="text-white">{{user.first_name}} {{ user.last_name }}</h3>
                            <br>
                            <p class="textTitle"><b>Profil : {{user.is_staff ? 'Lecteur' : 'Gestionnaire'}}</b></p>
                            <p class="textTitle"><b>Poste :</b> {{ user.extension[0]? user.extension[0].job_id.title :' - ' }} </p>
                            <p class="textTitle"><b>Mail :</b> {{ user.email }}</p>
                            <p class="textTitle"><b>No téléphone :</b> {{ formatPhoneNumber(user.extension[0] ? user.extension[0].mobile_num : '-') }}</p>
                            
                        </div>
                    </div>
                </div>
        </div>
    
    </div>
    <Chatroom/>
  </template>
  
  <script>
  import Header from '@/components/shared/Header.vue';
  import BackButton from '@/components/shared/BackButton.vue';
  import store from '@/store';
  import { getAllUsers} from '@/data/apiNess'
  import Chatroom from '@/components/chatroom/Chatroom.vue';
  import { formatPhoneNumber } from '@/data/formatPhoneNumber';
  export default {
    name: "reseauxNess",
    props: {},
  
    components: {
    BackButton,
    Header,
    Chatroom
},
  
    data() {
      return {
        user : store.state.user, 
        users : [], 
        search : ''
      };
    },
    async mounted(){
        this.users = await getAllUsers()
        
    },
    methods:{
      formatPhoneNumber,
      filteredUsers(){
        if(this.search!='')
            return this.users.filter(item=>{
                return item.last_name.toLowerCase().includes(this.search.toLowerCase()) || item.first_name.toLowerCase().includes(this.search.toLowerCase())
            })
        else return this.users
      }
    }
  
  };
  </script>
  <style>
  ::-ms-input-placeholder {
    color: white;
  }
  .badge {
      display: inline-block;
      padding: 5px 10px;
      /* background-color: #007BFF;  */
      background-color: darkcyan;
      color: #fff; 
      border-radius: 10px; 
    }
</style>