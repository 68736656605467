<template>
    <div class="popup">
        <div class="popup-inner" style="background-color: #4a2d1ee6;">
            <slot/>
            <div class="  p-3 " v-if="!clickButtonYES && !clickButtonNo" >       
                <div class="card" style="border-radius: 3% /7%;background-color: #ffffff00;border: 1px solid goldenrod;">
                    <br/> 
                    <table class="table borderless text-white">
                        <tbody>
                            <tr>
                                <td ><b>Type de reseau</b></td>
                                <td>{{ network.libelle }}</td>
                            </tr>
                            <tr>
                                <td><b>Date de soumission</b></td>
                                <td>{{ network.date_soumission }}</td>
                            </tr>
                            <tr>
                                <td><b>URL</b></td>
                                <td>{{ network.url }}</td>
                            </tr>
                            <tr>
                                <td><b>Description</b></td>
                                <td>{{ network.description }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br>
                <div class="card" style="border-radius: 3% /7%;background-color: #ffffff00;border: 1px solid goldenrod;" >
                    <br/> 
                    <table class="table borderless text-white" >
                        <tbody>
                            <tr>
                                <td><b>Etat</b></td>
                                <td>{{ network.validation }}</td>
                            </tr>
                            <tr>
                                <td><b>Demande traité par</b></td>
                                <td>{{ network.demandeur_nom }} {{ network.demandeur_prenom }}</td>
                            </tr>
                            <tr>
                                <td><b>Date de controle</b></td>
                                <td>{{ network.date_validation }}</td>
                            </tr>
                            <tr>
                                <td><b>Commentaire</b></td>
                                <td>-</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div v-show="user.profile.user_rights_id > 3" class="card p-4" style="background-color: #ffffff00;border: 1px solid goldenrod;" >
                <h2 class="text-white">Accepter l'ajout du site</h2>
                <hr>
                <div class="row pl-2 pr-2">
                    <div class="col-6 ">
                        <label class="textTitle mt-2 ">Secteur d'activités</label>
                        <button class="btn btn-primary float-right" @click="countSecteur++; coefficient.push(0)"><i class="fa-solid fa-plus"></i></button>
                        <div  v-for="index in countSecteur" :key="index" style="max-height: 200vh">
                            <br>
                            <div class="row">
                                <select class="col-8 form-control fieldLogin" v-model="secteur[index-1]" >
                                    <option v-for="secteur in secteurs" :key="secteur.id" :value="secteur.id" >
                                        {{ secteur.libelle }}
                                    </option>
                                </select>
                                <div class="col-4 mt-1 text-white">
                                    <div class="row">
                                        <div class="col-2">
                                            <button  @click="minusCoeff(index-1)"><i class="fa-solid fa-angle-left"></i></button>
                                        </div>
                                        <div class="col-6 text-center">
                                            {{ coefficient[index-1] }}
                                        </div>
                                        <div class="col-2">
                                            <button @click="addCoeff(index-1)"><i class="fa-solid fa-angle-right"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <br>
                    </div>
                    <div class="col-6 pl-5 pr-5">
                        <label class="textTitle mt-2 ">Copier les paramètres de </label>
                        <br><br>
                        <select class="form-control fieldLogin" v-model="reseau" @change="changeParameter()">
                            <option v-for="reseau in reseauxIn" :key="reseau.id" :value="reseau.id" >
                                {{ reseau.domain }}
                            </option>
                        </select>
                    </div>
                </div>
                <br><br>
                <div class="row pl-5 pr-5">
                    <div class="col-4">
                        <button class=" btn btn-success"  style="border-radius: 8% /25%; width: 100%;"  :disabled="secteur.length==0" @click="updateSite(true)">Appliquer</button>
                    </div>
                    <div class="col-4">
                        <button class=" btn btn-warning" @click="initParameter()"  style="border-radius: 8% /25%; width: 100%">Initialiser</button>
                    </div>
                    <div class="col-4">
                        <button class="btn btn-secondary" @click="clickButtonYES=!clickButtonYES" style="border-radius: 8% /25%; width: 100%">Annuler</button>
                    </div>
                    
                </div>
            </div> <br/>
            <div v-show="user.profile.user_rights_id > 3" class="card p-4" style="background-color: #ffffff00;border: 1px solid goldenrod;" >
                <h2 class="text-white">Refuser l'ajout du site</h2>
                <hr>
                <div class="row pl-2 pr-2">
                    <div class="col-6 pl-2 pr-2">
                        <ul class="list-group">
                            <li  class="list-group-item"><input type="checkbox"  value="L'URL n'est pas accessible" @change="addReason('L\'URL n\'est pas accessible')">L'URL n'est pas accessible </li>
                            <li  class="list-group-item"><input type="checkbox" value="Le nom n'est pas valide" @change="addReason('Le nom n\'est pas valide')">Le nom n'est pas valide </li>
                            <li  class="list-group-item"><input type="checkbox" value="Le type de réseau n'est pas approprié" @change="addReason('Le type de réseau n\'est pas approprié')">Le type de réseau n'est pas approprié </li>
                            <li  class="list-group-item"><input type="checkbox" value="Le site est inapproprié" @change="addReason('Le site est inapproprié')">Le site est inapproprié  </li>
                        </ul>
                        <br>
                        
                        <label class="text-white">Commentaire : </label>
                        <div class="form-group">
                            <textarea class="fieldLogin" v-model="comment"></textarea>
                        </div>
                    </div>
                    <div class="col-6 pl-2 pr-2">
                        <div class="card p-5">
                            <p>Le site {{ network.domain }} [ {{ network.url }}] indiqué comme étant un réseau {{ network.type_reseau }} a été refusé pour la/les raison(s) suivante(s) :</p>
                            <ul v-for="reason in reasons" :key="reason">
                                <li>{{ reason }}</li>
                            </ul>
                            <p v-show="comment!=''">
                                <b>Commentaire(s) : </b> {{ comment }}
                            </p>
                        </div>
                    </div>
                </div>
                <br><br>
                <div class="row pl-5 pr-5">
                    <div class="col-4">
                        
                        <button class=" btn btn-success" v-show="clickButtonNo" style="border-radius: 8% /25%; width: 100%;"  :disabled=" reasons.length==0 " @click="updateSite(false)">Appliquer</button>
                    </div>
                    <div class="col-4">
                        <button class=" btn btn-warning" @click="initParameter()" v-show="clickButtonYES"  style="border-radius: 8% /25%; width: 100%">Initialiser</button>
                    </div>
                    <div class="col-4">
                        <button class="btn btn-secondary" @click="clickButtonYES=false; clickButtonNo=false" style="border-radius: 8% /25%; width: 100%">Annuler</button>
                    </div>
                    
                </div>
            </div> <br/>
            <div class="row pl-5 pr-5" v-show="showCollab && !clickButtonYES && !clickButtonNo">
                <button class="col-4 btn btn-success" v-show="user.profile.user_rights_id > 3" @click="clickButtonYES=!clickButtonYES" style="border-radius: 8% /25%;">Accepter</button>
                <div class="col-4"></div>
                <button class="col-4 btn btn-danger" v-show="user.profile.user_rights_id > 3" style="border-radius: 8% /25%;"  @click="clickButtonNo=!clickButtonNo">Refuser</button>
            </div>
        </div>
    </div>
</template>    
<script>
import store from '@/store';
import {updateRequest, getSecteur, reseauxByType, getParameter} from '@/data/apiNess'
import { useToast } from 'vue-toastification';
const toast = useToast()
export default{
    name:'networkDetail', 
    props: ['showDetail', 'network'],
    data(){
        return{
            
            showCollab : store.state.showCollab,
            secteur : [], 
            secteurs: [],
            secteurActivites : [], 
            reseauxIn : [],
            reseau : -1,
            countSecteur : 0, 
            coefficient : [], 
            clickButtonYES : false, 
            clickButtonNo : false, 
            reasons : [], 
            user : store.state.user,
            comment :''
        }
    }, 
    async mounted(){
        this.secteurs = await getSecteur()
        this.reseauxIn = await reseauxByType(this.network.type_reseau)
    },
    methods:{
        getUserByID(id){
            console.log(id)
        },
       
        async updateSite(bool){
            
            let secteur_activite = []
            this.secteur.forEach((item, index)=>{
                const objectSecteur = this.secteurs.filter(sect=>{return sect.id==item})
                secteur_activite.push(
                    {
                        "id-secteur": item,
                        "valeur": this.coefficient[index],
                        "secteur": objectSecteur[0].libelle,
                    }
                )
            })
            
            const payload = {
                "id": this.network.id,
                "user-id": this.user.Id,
                "validated": bool,
                "secteur-activite": secteur_activite,
                "comment" : this.comment
            }
            console.log(payload)
            const result = await updateRequest( payload)
            toast.success(JSON.stringify(result))
            this.$emit('close')
        }, 
        addCoeff(indice){
            if(this.coefficient[indice]<1)
                this.coefficient[indice] += 0.1
            this.coefficient[indice] = parseFloat(this.coefficient[indice].toFixed(2));
        },
        minusCoeff(indice){
            if(this.coefficient[indice]>0)
                this.coefficient[indice] -= 0.1
            this.coefficient[indice] = parseFloat(this.coefficient[indice].toFixed(2));
        },
        async changeParameter(){
            this.secteur = [], 
            this.countSecteur = 0
            this.coefficient = []
            const parameters = await getParameter(this.reseau)
            parameters.coefficients.forEach(item=>{
                this.secteur.push(item['id-secteur'])
                this.coefficient.push(item.valeur)
            })
            this.countSecteur = this.secteur.length
        },
        initParameter(){
            this.secteur = [], 
            this.countSecteur = 0
            this.coefficient = []
            this.reseau = -1
        }, 
        addReason(reason){
            const index = this.reasons.findIndex(item=>{
                return item==reason
            })
            if(index==-1) this.reasons.push(reason)
            else this.reasons.splice(index, 1)
        }
    }
}
</script>