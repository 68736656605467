<template>
  <div class="popup">
    <div class="popup-inner" style="background-color: #1c3615;">
      <slot />
      <div class="popup-header text-center text-white">
        <h3>Modification de la tâche</h3>
        <hr style="border-color: white;">
      </div>

      <div class="pl-5 pr-5">
        <div class="form-group">
          <label class="text-white">Tâche</label>
          <input type="text" class="form-control fieldLogin"  v-model="tache" disabled />
        </div>
        <div class="form-group">
          <label class="text-white">Date fin</label>
          <input type="text" class="form-control fieldLogin" v-model="date_fin" disabled />
        </div>
        <div class="form-group">
          <label class="text-white">Etat</label>
          <select class="form-control fieldLogin" v-model="etat">
            <option v-for="status in statusOptions" :key="status" :value="status">
              {{ status }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label class="text-white">Commentaires</label>
          <textarea style="height:100px" class="form-control fieldLogin" v-model="message" required></textarea>
        </div>

        <div align="center">
          <button class="btn btn-secondary" @click="updateTask">Envoyer</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { updateTask } from '@/data/apiPythie';
import { useToast } from 'vue-toastification';

const toast = useToast();
export default {
  props: ['objet', 'type', 'user'],
  data() {
    return {

      tache: '',
      date_fin: '',
      etat: '',
      message: '',
      // Définir les statuts possibles, y compris les valeurs dynamiques
      statusOptions: ['En cours' ,"À tester" ,  "Bloqué", 'Terminé']
    };
  },
  mounted() {
    // Pré-remplir les champs avec les données de l'objet transmis
    if (this.objet) {
      this.tache = this.objet.tache || '';
      this.date_fin = this.objet.data_fin || '';
      this.etat = this.objet.statut || 'Blank'; // Par défaut, "Blank" est sélectionné si aucun statut n'est défini
      this.message = '';
    }

    console.log(this.user);
  },
  methods: {
    

    async updateTask() {
      const idTache = this.objet.id;
      const now = new Date().toISOString(); // Obtenir la date et heure actuelles au format ISO 8601

      var payload = {
        "statut": this.etat,
        "signaler": false,
        "isAddedByAdmin": false,
        "commentaires": [
          {
            "author": this.user.Username,
            "comment": this.message,
            "timestamp": now, // Utiliser la date et heure actuelles
          },
        ],
        "utilisateur": this.user.Id,
      };

      console.log(payload , idTache);
      await updateTask(payload, idTache);
      toast.success("Modification taches reussie")
      this.$emit('close');
      setInterval(()=>{
          this.$router.go()
      }, 1500)
    }
  },



};
</script>
