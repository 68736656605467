<template>
    <div class="h-100" id="thotea" >
        <Header :user="user" page="test" logo="thotea" />
            <back-button></back-button>
                <div class="p-3 ">
                        <div class="row">
                            <div class="col-12 pr-5 justify-content-center align-items-center">
                                <h1 v-if="user" class="text-white"> Bienvenue {{user.Username}}!</h1>
                            </div>
                            
                        </div>
                        <br>
                        <p class="pl-5 pr-5 text-white">Thotea est l’outil d’évaluation des compétences fonctionnelles et techniques, il permet par le biais de plusieurs tests d’évaluer le niveau « technique » des candidats.</p>
                        <!-- <p class="text-white">Vous y trouverez des questions concernant l'outil et des technologies ainsi que des questionnaires de français, d'anglais, de culture générale et de logique  </p> -->
                        <p class="text-white">Bonne chance ! &#128522;</p>
                        <br>
                        <div class="row pl-5 pr-5 ">
                            <div class="col-12">
                                <h2 class="textTitle  pl-4">Vos Questionnaires :</h2>
                            </div>
                        </div>
                        <div class="row justify-content-center align-items-center" style="overflow-y: auto;">
                            <div class="col-md-12 pr-5 pl-5 " >
                                <div class="card" style="max-height: 520px;background-color: rgba(255, 255, 255, 0);">
                                    
                                    <div class="row" v-if="questionnaires.length>0" align="center">
                                        
                                            <div class="card col-md-4 p-5" v-for="questionnaire in questionnaires" :key="questionnaire.id" style="background-color: rgba(255, 255, 255, 0);border-color:  rgba(255, 255, 255, 0)">
                                                
                                                <h4 class="cardTitle p-2"> {{questionnaire.questionnaireAssigned.Title}} </h4>
                                                <br>
                                                <div class="row">
                                                    <div class="col-md-6 text-white">
                                                        <img src="@/assets/icons/difficulty_icon.png" alt="difficulty_icon" style="width: 80px;height: 80px;"/>
                                                        <p><small class="textTitle">DIFFICULTE: </small> 1 - 3 </p>
                                                    </div>
                                                    <div class="col-md-6 text-white">
                                                        <img src="@/assets/icons/temps_icon.png" alt="temps_icon"  style="width: 80px;height: 80px;"/>
                                                        <p><small class="textTitle">TEMPS: </small>{{questionnaire.time}} minutes</p>
                                                    </div>
                                                </div>
                                                <br>
                                                <!-- <td> {{questionnaire.numberOfQuestion}} </td> -->
                                                <router-link :to="{name: 'test', params:{'id': questionnaire.id}}" v-if="!questionnaire.isArchived" ><button class="btn btn-block btn-warning text-white round-border" > Démarrer le test</button></router-link>
                                                <button class="btn btn-warning " disabled v-else  > Test effectué</button>
                                                
                                            </div>
                                    </div>
                                    <p class="text-center text-white pt-5" v-else>Pas de questionnaires assignés</p>
                                    
                                </div>
                                
                                <br>
                                <div class="card" v-if="questionnairesArchived.length>0" hidden>
                                    <div class="card-header title justify-content-center align-items-center">
                                        <h2 class="float-left" >Vos tests effectués</h2>
                                        <router-link to="/ResultPage" class="float-right pt-2">Voir vos résultats</router-link>
                                    </div>
                                    <div class="card-content">
                                        
                                        <div class="row ">
                                            <div class="col-md-3 pb-4 justify-content-center align-item-center" v-for="questionnaireArchived in questionnairesArchived" :key="questionnaireArchived.id">
                                                
                                                <div class=" p-2 caseTest ">
                                                    <div class="">
                                                        <i class="fas fa-file-pen" aria-hidden="true" style="width: 50%;height: 50%;color:#fff"></i>
                                                    </div>
                                                    {{ questionnaireArchived.questionnaireAssigned.Title }}
                                                    <hr>
                                                <button class="btn btn-primary">Voir résultat</button>
                                                </div>
                                            </div>
                                        </div>  
                                    </div>
                                </div> 
                               
                            </div>
                            
                        </div>
                    
                        <!-- <router-link to="/ResultPage" v-if="questionnairesArchived.length>0"><a class=" btn btn-lg btn-info mr-2 mt-2">Voir vos résultats</a></router-link>                              -->
                    </div>
            <Chatroom />
        </div>
      </template>
<script setup>
    import Chatroom from '@/components/chatroom/Chatroom.vue'
</script>
      <script>
    import store from '@/store'
    import Header from '@/components/shared/Header.vue';
    import axios from 'axios'
    import data from '@/data/data'
    import BackButton from '@/components/shared/BackButton.vue'
      export default {
        name: 'welcomePage',
        components:{Header, BackButton},
        
        mounted(){
            if(!this.user)
                this.$router.push('/login')
            else {
                console.log(this.user)
                if(!this.user.Id) this.$router.push('/')
                else{
                    if(this.user.profile.user_rights_id<=5 || !this.$store.state.showCollab)
                        axios.get(data.baseUrl+'thotea/api/getAssignQuestionnaires/?bulmaId='+this.user.Id)
                        .then(response=>{
                            response.data.forEach(element => {
                                    if(element.isArchived==false)
                                        this.questionnaires.push(element)
                                    else this.questionnairesArchived.push(element)
                                });
                                
                            })
                    else this.$router.push('/listquestionnaire')
                }
                
            }
        },
        data(){
            return {
                user : store.state.user, 
                questionnaires : [], 
                questionnairesArchived : []
            }
        }, 
        methods:{
            goToTest(){
                this.$router.push('/test')
            }
        }
      }
</script>
<style>
    .contentBg{
        background-color: #9c4c77;;
    }
    .bgOpac{
        background-color: rgba(255, 255, 255, 0.053);
    }
    .title{
        background-color: rgba(36, 142, 175, 0.31);
    }
    .caseTest{
        background-color: aliceblue;
        padding: 5px
    }
    .round-border{
        border-radius: 10% / 50%;
        width: 50%;
    }
    .row::-webkit-scrollbar {
        background:transparent !important;
    }
    #thotea{
        min-height: 100vh;
        background-image: url('@/assets/Background-01.jpg');
        background-size: cover; 
        background-repeat: no-repeat; 
        overflow-y: auto;
    }
</style>
     