<template>
  <div id="thotea">
    <Header :user="user" page="resultat" logo="thotea" />
    <div >
      <div class="row">
        <back-button />


      </div>
      <div class="row" align="center">
        <h2 class="cardTitle text-center p-3 margincardTitle">Résultat du test de {{ user.Username }}</h2>
      </div>
      <div class="row  " v-if="!isLoading && list.length > 0">

        <div class="col-md-4 ">
          <my-results class="ml-3" :methods="viewResults" :list="list" />
        </div>
        <!-- <div class="col-1"></div> -->
        <div class="col-md-4">
          <div id="chart">
            <apexchart type="radar" :options="chartOptions" :series="series"></apexchart>

          </div>
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-3 mt-5 pl-2 pr-5">
          <my-box :item="item" />
        </div>

      </div>
     

      <!-- Afficher ce message si la liste est vide après le chargement -->
      <div class="row" v-else-if="!isLoading && list.length === 0" style="justify-content: center;">
         <h4 style="color:white; margin-top: 20px" class="text-center">Vous n'avez pas encore effectué aucun test. Pour voir les tests qui vous sont assignés,
          <router-link to="/" class="btn-link">
            cliquez ici
          </router-link>
        </h4>
        
         
      </div>
      <!-- <button-finish /> -->
    </div>
     

    <Chatroom />
  </div>
</template>
<script setup>
import Chatroom from "@/components/chatroom/Chatroom.vue";
</script>
<script>
import axios from "axios";

import MyBox from "@/components/MyBox.vue";
import MyResults from "@/components/MyResults.vue";
import BackButton from "@/components/shared/BackButton.vue";
// import ButtonFinish from "@/components/ButtonFinish.vue";
import store from '@/store';
import Header from '@/components/shared/Header.vue';
import VueApexCharts from 'vue3-apexcharts';
import data from '@/data/data'
export default {
  name: "App",
  components: {
    MyResults,
    MyBox,
    // NavBar,
    BackButton,
    // ButtonFinish,
    //PieChart,
    Header,
    apexchart: VueApexCharts
  },

  data() {
    return {
      list: [],
      index: 0,
      item: {},
      user: store.state.user,
      series: [],
      chartOptions: {},
      allSeries: [],
      labels: [],
      isLoading: true,

    };
  },

  async mounted() {
    try {
      let result = await axios.get(
        data.baseUrl + "thotea/api/questiongrade/?userId=" + this.user.Id + "&qtyTest=15"
      );

      this.list = result.data;

      if (this.list.length > 0) {
        this.item = this.list[0];
        this.list.forEach(item => {
          this.allSeries.push(item.results)
          this.labels.push(item.name)
        })
        this.series = [{
          name: 'Series 1',
          data: this.allSeries,
        }]

        this.chartOptions = {
          chart: {
            height: 750,
            type: 'radar',
            dropShadow: {
              enabled: true,
              blur: 1,
              left: 1,
              top: 1
            }
          },
          title: {
            text: ''
          },
          stroke: {
            width: 2
          },
          fill: {
            opacity: 0.1
          },
          markers: {
            size: 0
          },
          xaxis: {
            categories: this.labels
          }
        };
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des données:", error);
    } finally {
      this.isLoading = false;  // Indiquer que le chargement est terminé
    }
  },


  methods: {
    viewResults(index) {
      this.item = this.list[index];
    },
    sortList(a, b) {
      var dateA = new Date(a.date).getTime();
      var dateB = new Date(b.date).getTime();
      return dateA > dateB ? 1 : -1;
    }
  },
};
</script>

<style>
.fond {
  background-color: #efeaea;
}

#chart {
  width: 100%; /* Assurer que le graphique occupe toute la largeur de la colonne */
  height: 1000px; /* Ajuster la hauteur selon vos besoins */
  max-width: 600px; /* Vous pouvez limiter la largeur pour éviter une trop grande expansion */
  margin: 0 auto; /* Centrer le conteneur */
  background-color: #ffffff00;
  border-radius: 2%;
}



.margincardTitle {
  margin-left: 38%;
  /* margin-top: -15px; */
}

.box::-webkit-scrollbar {
  background: transparent !important;
}

@import "~bootstrap/dist/css/bootstrap.css";
</style>
