<template id="comment-form">
  <div id="login">
    <div id="content">
      <img
        v-if="interval"
        src="@/assets/login_page/Ecran_1_Astronaut.gif"
        alt="LOGO"
      />
      <div v-else>
        <div
          class="row justify-content-center align-items-center cardLogin "
        >
          <div class="col-12 col-md-6">
            <div class="card formulaire">
              <div
                class="card-header d-flex align-items-center"
                style="border-radius: 2% / 22%"
              >
                <img src="@/assets/login_page/Baobab.png" />
              </div>
              <div class="row pr-5 pl-5 pt-4">
                <div class="col-12 col-md-5 text-center">
                  <img
                    src="@/assets/login_page/Rotating_LOGO.gif"
                    alt="LOGO"
                    class="img-fluid rotating-logo"
                  />
                </div>
                <div class="col-12 col-md-5 mt-4">
                  <h4 class="text-white text-center textBienvenu">
                    Bienvenue sur
                  </h4>
                  <br />
                  <h3 class="text-white text-center textBienvenu bold">
                    BULMA
                  </h3>
                </div>
              </div>
              <div class="pr-5 pl-5 pb-4">
                <br />
                <div class="row form-group">
                  <label
                    class="col-12 col-md-3 text-white text-md-right"
                    style="font-size: small"
                    for="email"
                    >Identifiant</label
                  >
                  <div class="col-12 col-md-9">
                    <input
                      v-model="email"
                      class="form-control fieldLogin"
                      :class="{
                        'is-invalid': !validEmail(email) && emailBlured,
                      }"
                      @blur="emailBlured = true"
                    />
                    <div class="invalid-feedback">
                      Une adresse courriel valide est requise
                    </div>
                  </div>
                </div>
                <div class="row form-group pt-2">
                  <label
                    class="col-12 col-md-3 text-white text-md-right"
                    style="font-size: small"
                    for="password"
                    >Mot de passe</label
                  >
                  <div class="col-12 col-md-9">
                    <input
                      v-model="password"
                      type="password"
                      class="form-control fieldLogin"
                      @keyup.enter="login"
                    />
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-12 col-md-3"></div>
                  <div class="col-12 col-md-9">
                    <div class="form-group text-center">
                      <button
                        type="submit"
                        @click.stop.prevent="login"
                        class="btn btnLogin"
                      >
                        Se connecter
                      </button>
                    </div>
                  </div>
                </div>
                <hr />
                <span class="text-white" style="font-size: small; float: left"
                  >Nouveau candidat? Cliquez
                  <router-link to="/register">ici</router-link></span
                >
                <router-link
                  to=""
                  @click="formForgetPassword = true"
                  class="text-white"
                  style="font-size: small; float: right; text-align: right"
                  >Mot de passe oublié
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span
        class="footer text-white pt-5"
        style="float: right; font-style: italic; font-size: smaller"
      >
        Version 0.1.0</span
      >
    </div>
  </div>
  <ForgetPassword v-if="formForgetPassword" @close="formForgetPassword = false">
    <button
      type="button"
      class="btn float-right text-white"
      @click="formForgetPassword = false"
    >
      x
    </button>
  </ForgetPassword>
</template>
  
  
  <script>
import store from "@/store";
import ForgetPassword from "@/views/Accueil/popup/ForgetPassword.vue";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "loginComponent",
  components: { ForgetPassword },
  data: function () {
    return {
      email: "",
      emailBlured: false,
      valid: false,
      submitted: false,
      password: "",
      user: store.state.user,
      interval: true,
      formForgetPassword: false,
    };
  },
  mounted() {
    if (this.user) {
      if (this.user.Id) {
        this.$router.push("/");
      } else
        this.$store
          .dispatch("logout")
          .then((res) => {
            console.log(res);
          })
          .catch((error) => console.log(error));
    }
    setInterval(() => {
      this.interval = false;
    }, 1500);
  },
  methods: {
    validate: function () {
      this.emailBlured = true;
      if (this.validEmail(this.email)) {
        this.valid = true;
      }
    },
    validEmail: function (email) {
      var re = /(.+)@(.+){2,}\.(.+){2,}/;
      return re.test(email.toLowerCase());
    },
    login: function () {
      this.validate();

      if (this.valid) {
        this.submitted = true;
        this.$store
          .dispatch("login", {
            username: this.email.toLowerCase(),
            password: this.password,
          })
          .then((response) => {
            // console.log(response)
            if (response.data.message || response.data.Error) {
              toast.error(response.data.message);
              this.$router.push("/login");
            } else {
              this.$router.push("/home");
            }
          })
          .catch((error) => {
            if (error.request) {
              console.log(error.request);
              this.$router.push("/login");
              if (error.request.response) {
                const message = error.request.response.split(":")[1];
                toast.error(
                  message.substring(1, message.length - 1).replace('"', " ")
                );
              }
            } else if (error.message) {
              console.log(error.message);
              toast.error(error.message);
            } else if (error.response) console.log(error.response);
          });
      }
    },
  },
};
</script>
  
<style>
#login {
  display: flex;              /* Utilisation de Flexbox */
  justify-content: center;     /* Centrer horizontalement */
  align-items: center;         /* Centrer verticalement */
  min-height: 100vh;           /* Hauteur minimale égale à 100% de la fenêtre */
  background-image: url("@/assets/login_page/background_home.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.content {
  height: 100%;
  opacity: 0.8;
}
/*
.cardLogin {
  height: 60vh;
}*/

.formulaire {
  background-color: #ffffff38;
  border-color: #237eaf;
  border-radius: 3%;
}
.fieldLogin {
  background-color: #ffffff38;
  border-color: white;
  color: white;
  border-radius: 2% / 20%;
}
.btnLogin {
  border-radius: 8% / 30%;
  background-color: white;
  width: 40%;
  /* margin-left: 25%; */
  align-items: center;
  color: black;
  
}

.footer {
  position: fixed;
  height: 100px;
  bottom: 0;
  width: 90%;
}
</style>