import data from "@/data/data"
import axios from 'axios'

import { useToast } from "vue-toastification";
const toast = useToast()

export async function resetPassword(admin_id, admin_password, user_id) {
    try {
        const payload = {
            "admin_id": admin_id,
            "admin_password": admin_password,
            "user_id": user_id 
        };
        console.log(payload);
        const response = await axios.put(data.baseUrl + "authentication/api/admin/reset-user-password/", payload);
        console.log("Réponse du serveur :", response);
        if (!JSON.stringify(response.data).includes("Error")){
            toast.success("Reinitialisation mot de passe reussie")
            return response.data;
        }
    } catch (error) {
        if (error.response && error.response.status === 400) {
            const errorMessage = error.response.data.non_field_errors && error.response.data.non_field_errors[0];
    
            // Affichage du message d'erreur spécifique
            if (errorMessage) {
                if (errorMessage === "Administrator password is incorrect.") {
                    toast.error("Le mot de passe saisi est incorrecte");
                }
            } else {
                toast.error("Une erreur s'est produite. Veuillez réessayer.");
            }
        } else {
            // Erreur réseau ou autre type d'erreur
            toast.error("Une erreur s'est produite. Veuillez réessayer.");
        }
        console.error(error);
        
    }
}



export async function changePassword (idUser, current_password , new_password ){
    try{
        const payload = {
            "current_password": current_password,
            "new_password": new_password,
        }
        console.log(payload)
        const response = await axios.put(data.baseUrl + "authentication/api/users/"+idUser+"/update-password/", payload)
        if (!JSON.stringify(response.data).includes("Error")){
            toast.success("Modification mot de passe reussie")
            return response.data;
        }
    }catch(error) {
        if (error.response && error.response.status === 400) {
            const errorMessage = error.response.data.non_field_errors && error.response.data.non_field_errors[0];
    
            // Affichage du message d'erreur spécifique
            if (errorMessage) {
                if (errorMessage === "Current password is incorrect.") {
                    toast.error("L'ancien mot de passe est incorrect.");
                } else if (errorMessage === "The new password must be different from the current password.") {
                    toast.error("Le nouveau mot de passe doit être différent de l'ancien.");
                } else {
                    toast.error("Une erreur s'est produite. Veuillez réessayer.");
                }
            } else {
                toast.error("Une erreur s'est produite. Veuillez réessayer.");
            }
        } else {
            // Erreur réseau ou autre type d'erreur
            toast.error("Une erreur s'est produite. Veuillez réessayer.");
        }
        console.error(error);
    } 
}
export async function forgetPassword (idUser, new_password, confirm_password){
    try{
        const payload = {
            "mode": "FORGET_PWD",
            "new_password": new_password,
            "confirm_password": confirm_password
        }
        const response = await axios.post(data.baseUrl + "authentication/api/users/"+idUser+"/update-password/", payload)
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
      console.log(error)
    } 
}
export async function forgetPassword2 (new_password, id_user, token){
    try{
        const payload = {
            "new_password": new_password,
            "encrypted_user_id": id_user, 
            "token" : token
        }
        const response = await axios.post(data.baseUrl + "authentication/api/users/reset-password/", payload)
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
        console.log(error)
        toast.error("Erreur de token ou du serveur")
    } 
}
export async function sendAdressMail ( email){
    try{
        const payload = {
            "email": email,
        }
        console.log(payload)
        const response = await axios.post(data.baseUrl + "authentication/api/users/forgot-password/", payload)
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
      console.log(error)
    } 
}

export async function updateProfile (id, payload){
    try{
        const response = await axios.put(data.baseUrl + "authentication/api/api/userprofile/"+id+"/", payload)
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
      console.log(error)
    } 
}


export async function deleteUser(id){
    try{
        const response = await axios.post(data.baseUrl + "authentication/api/api/allUsers/"+id+"/archive/")
        if (!JSON.stringify(response.data).includes("Error"))
            return response.data
        else {
            toast.error(JSON.stringify(response.data))
        }
    }catch(error) {
      console.log(error)
    } 
}