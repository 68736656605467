<template>
    <div class="container-fluid accueil">
        <br><br>
        <BackButton/>
        <div class="row justify-content-center align-items-center cardLogin"> 
            <div class="col-12 col-md-8">
                <div class="card formulaire text-white p-5">
                   
                    <div class="pl-5 pr-5">
                            <h3>Réinitialiser mon mot de passe</h3>
                            <br>
                            <hr>
                            <div class="form-group">
                                <label class="text-white float-left">Nouveau mot de passe</label>
                                <input type="password" class="form-control fieldLogin" v-model="newPassword"/>
                            </div>
                            <div class="form-group">
                                <label class="text-white float-left">Saisir le mot de passe à nouveau</label>
                                <input type="password" class="form-control fieldLogin" v-model="newPassword2"/>
                            </div>
                            <br>
                            <button align="center" class="btn col-3 btn-primary" :disabled=" newPassword=='' || newPassword2=='' || newPassword!=newPassword2" @click="changePassword()"> Valider</button>
                            
                            
                    </div>
                            
                </div>
            </div>
        </div>
        
        
    </div>
</template>
<script>
import BackButton from '@/components/shared/BackButton.vue';
import { forgetPassword2 } from '@/data/apiUser';
import { useToast } from 'vue-toastification';
const toast = useToast()
export default {
    name: 'reset-password',
    data() {
        return {
            id_user : this.$route.params.id_user,
            token : this.$route.params.token,
            newPassword : '', 
            newPassword2 : ''
        };
    },
    async mounted() {
    },
    methods:{
        async changePassword(){
            const result = await forgetPassword2(this.newPassword,this.id_user, this.token)
            if(result.error) toast.error(result.error)
            else toast.success("Votre mot de passe a été reinitialisé")
            this.$router.push('/')
        }
    },
   
    components: {  BackButton }
}
</script>
<style>
   
</style>
