<template>
    <div class="popup">
        <div class="popup-inner" style="background-color: #4a2d1ee6;">
            <slot/>
           
            <div class="card p-4" style="background-color: #ffffff00;border: 1px solid goldenrod;" >
                <h2 class="text-white">Coefficients du site {{ site.domain }}</h2>
                <hr>
                <div class="row pl-2 pr-2">
                    <div class="col-12 " v-if="parameters"  style="max-height: 50vh;overflow-y: auto;">
                        <label class="textTitle mt-2 ">Secteur d'activités</label>
                        <div  v-for="(coeff, index) in parameters.coefficients" :key="coeff.id">
                            <br>
                            <div class="row" >
                                <strong class="col-7 text-white">{{ coeff.secteur}}</strong>
                                <div class="col-5 mt-1 text-white">
                                    <div class="row">
                                        <div class="col-2">
                                            <button  @click="minusCoeff(index)"><i class="fa-solid fa-angle-left"></i></button>
                                        </div>
                                        <div class="col-6 text-center">
                                            {{ coeff.valeur }}
                                        </div>
                                        <div class="col-2">
                                            <button @click="addCoeff(index)"><i class="fa-solid fa-angle-right"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <br>
                    </div>
                   
                </div>
                
            </div>
            <br>
            <div class="row pl-5 pr-5" >
                <button class="col-4 btn btn-success" v-show ="user.profile.user_rights_id > 3 " style="border-radius: 8% /25%;" @click="updateCoefficients()">Appliquer</button>
                <div class="col-4"></div>
                <button class="col-4 btn btn-danger" v-show ="user.profile.user_rights_id > 3 " style="border-radius: 8% /25%;" @click="$emit('togglePopup')"  >Annuler</button>
                <button class="col-4 btn btn-danger" v-show ="user.profile.user_rights_id < 4 " style="border-radius: 8% /25%;" @click="$emit('togglePopup')"  >Fermer</button>
  
            </div>
        </div>
    </div>
</template>    
<script>
import store from '@/store';
import {updateCoefficient} from '@/data/apiNess'
import { useToast } from 'vue-toastification';
const toast = useToast()
export default{
    name:'networkDetail', 
    props: [ 'site', 'parameters'],
    data(){
        return{
            user : store.state.user, 
            showCollab : store.state.showCollab,
        }
    }, 
    methods:{
       
        async updateCoefficients(){
            var payload = {
                "coefficient-reseaux" : [
                    {
                        "id": this.site.id,
                        "nom-du-reseau":  this.site.domain, 
                        "coefficient-par-secteur": this.parameters.coefficients
                    }
                ]
            }
            const result  = await  updateCoefficient(payload)
            toast.success(JSON.stringify(result))
            this.$emit('togglePopup')
        }, 
        addCoeff(indice){
            if (this.parameters.coefficients[indice].valeur < 1) {
                this.$emit('updateCoeff', indice, 0.1);
            }
        },
        minusCoeff(indice){
            if (this.parameters.coefficients[indice].valeur > 0) {
                this.$emit('updateCoeff', indice, -0.1);
            }
        },
        
    }
}
</script>