<template>
  <div class="popup" :style="{ backgroundColor: overlayColor }">
    <div class="popup-inner justify-content-center" :style="{ backgroundColor: modalBackgroundColor, border: `3px solid ${borderColor}`, borderRadius: '20px' }">
      <div class="popup-content" >
        <div class="form-group">
          <label class="text-white text-validation">Voulez-vous vraiment {{type}}<strong>{{ title }} ? </strong></label>
        </div>
        <button class="btn mr-1 btn-validation" :style="{ backgroundColor: confirmButtonColor, color: confirmButtonTextColor }" @click="confirmAction">{{textConfirmButton}}</button>
        <button class="btn ml-1 btn-validation" :style="{ backgroundColor: cancelButtonColor, color: cancelButtonTextColor }" @click="closePopup">Annuler</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    textConfirmButton: {
      type: String,
      required: true,
    },
    modalBackgroundColor: {
      type: String,
      default: '#9a4c76', 
    },
    borderColor: {
      type: String,
      default: 'green', 
    },
    confirmButtonColor: {
      type: String,
      default: '#dc3545', 
    },
    confirmButtonTextColor: {
      type: String,
      default: 'white', 
    },
    cancelButtonColor: {
      type: String,
      default: '#007bff', 
    },
    cancelButtonTextColor: {
      type: String,
      default: 'white', 
    },
    overlayColor: {
      type: String,
      default: 'rgba(0,0,0,0.9)',
    },
  },
  methods: {
    confirmAction() {
      this.$emit('confirm'); 
    },
    closePopup() {
      this.$emit('close'); // Émet un événement pour fermer le modal
    },
  },
};
</script>

<style scoped>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-inner {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  width:600px;
  height: 300px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

}

.popup-content{
   
    text-align: center;
    justify-content: center;
    align-items: center;

}

.text-validation{
    font-size:2vh;
}

.btn-validation{
    font-size:2vh;
}
</style>