import { createRouter , createWebHistory} from 'vue-router'
import ResultPage from '@/views/Thotea/ResultPage.vue'
import DetailResult from '@/views/Thotea/DetailResult.vue'
import ResultatsDuQuestionnaire from '@/views/Thotea/ResultatsDuQuestionnaire.vue'
import ListQuestionnaire from '@/views/Thotea/Questionnaires/ListQuestionnaire.vue'
import VoirResultat from '@/views/Thotea/Questionnaires/VoirResultat.vue'
import questionnaireUserAnswer from '@/views/Thotea/Questionnaires/questionnaireUserAnswer.vue'
import ListQuestions from '@/views/Thotea/Questionnaires/ListQuestions.vue'
import AssignCandidate from '@/views/Thotea/Questionnaires/AssignCandidate.vue'
import addQuestionnaire from '@/views/Thotea/Questionnaires/addQuestionnaire.vue'
import editQuestionnaire from '@/views/Thotea/Questionnaires/editQuestionnaire.vue'
import Index from '@/views/Thotea/Index.vue'
import Login from '@/components/Login.vue'
import EditProfil from '@/views/Thotea/EditProfil.vue'
import Welcome from '@/views/Thotea/Welcome.vue'
import Test from '@/views/Thotea/Test.vue'
import QuizzesManagement from '@/views/Thotea/Quizzes/QuizzesManagement'
import Profil from '@/views/Thotea/users/Profil'
import Candidates from '@/views/Thotea/candidates/Candidates'
import AddCandidate from '@/views/Thotea/candidates/AddCandidate'
import AddReseau from "@/views/Ness/AddReseau"
import Dashboard from '@/views/Thotea/Dashboard'
import DashboardNess from '@/views/Ness/Dashboard'
import Accueil from '@/views/Accueil/Accueil'
import Settings from '@/views/Accueil/Settings'
import Profile from '@/views/Ness/Profile'
import Reseaux from '@/views/Ness/Reseaux'
import Search from '@/views/Ness/Search'
import Histories from '@/views/Ness/Histories'
import History from '@/views/Ness/History'
import Demande from '@/views/Ness/gestionnaire/Demande'
import Users from '@/views/Ness/gestionnaire/Users'
import UserHistory from '@/views/Ness/gestionnaire/UserHistory'
import NetworkGest from '@/views/Ness/gestionnaire/NetworkGest'
import StatutDemandeReseau from '@/views/Ness/StatutDemandeReseau'
import ThemisView from '@/views/Themis/ThemisView';
import ThemisHome from '@/views/Themis/ThemisHome';
import ThemisProfile from '@/views/Themis/ThemisProfile';
import LaborCode from '@/views/Themis/LaborCode';
import CollectiveAgreements from '@/views/Themis/CollectiveAgreements';

import MyCompany from '@/views/Themis/MyCompany';
import Faq from '@/views/Themis/Faq';
import FaqGest from '@/views/Themis/gestionnaire/FaqGest';

import UserThemis from '@/views/Themis/gestionnaire/Users';
import UsersPythie from '@/views/Pythie/Users'

import DashboardPythie from '@/views/Pythie/Dashboard'
import ProfilePythie from '@/views/Pythie/Profile'
import UserprofilePythie from '@/views/Pythie/UserProfile'
import MyFavoris from '@/views/Themis/MyFavoris.vue'
import Notification from '@/views/Themis/Notification.vue'
import ProfileUser from '@/views/Pythie/ProfileUser.vue'
import CareerPlan from '@/views/Pythie/CareerPlan.vue'
import ActivitiesTracking from '@/views/Pythie/ActivitiesTracking.vue'
import ActivitiesUser from '@/views/Pythie/ActivitiesUser.vue'
import AssignFormation from '@/views/Pythie/AssignFormation'
import MyQuestions from '@/views/Themis/MyQuestions'
import NewPassword from '@/views/Accueil/NewPassword.vue'
import MyCompagnyAdmin from '@/views/Themis/gestionnaire/MyCompagnyAdmin.vue'
const routes = [
    {
      path: '/register',name: 'Home',component: Index
    },
    {
      path: '/vger',name: 'vger',component: Index
    },
    {
      path: '/reset-password/:id_user/:token',name: 'resetPassword',component: NewPassword
    },
    {
      path: '/dashboard',name: 'dashboard',component: Dashboard
    },
    {
      path: '/login',name: 'Login',component: Login
    },
    {
      path: '/edit',name: 'EditProfil',component: EditProfil,
    },
    {
      path: '/',name: 'home',component: Welcome
    },
    {
      path: '/test/:id',name: 'test',component: Test
    },
    {
      path: '/DetailResult/:idQuest/:userId',name: 'Detailresult',component: DetailResult
    },
    {
      path: '/questionnaireUserAnswer/:idUser/:idQuest',name: 'questionnaireUserAnswer',component: questionnaireUserAnswer
    },
    {
      path: '/ResultPage',name: 'ResultPage',component: ResultPage
    },
    {
      path: '/resultat/:id',name: 'resultat',component: ResultatsDuQuestionnaire
    },
    {
      path: '/listquestionnaire',name: 'listquestionnaire',component: ListQuestionnaire
    }, 
    {
      path: '/quizz-management',name: 'quizz-management',component: QuizzesManagement
    },
    {
      path: '/questionnaire/:id',name: 'questionnaire',component: ListQuestions
    },
    {
      path: '/voirResultat/:id',name: 'voirResultat',component: VoirResultat
    },
    {
      path: '/answers/:idUser',name: 'answers',component: questionnaireUserAnswer
    },
    {
      path: '/assignerCandidate/:id',name: 'assignerCandidat',component: AssignCandidate
    }, 
    {
      path: '/addQuestionnaire',name: 'addQuestionnaire',component: addQuestionnaire
    }, 
    {
      path: '/profil',name: 'profile',component: Profil
    }, 
    {
      path: '/candidates',name: 'listCandidate',component: Candidates
    }, 
    {
      path: '/candidates/add',name: 'addCandidate',component: AddCandidate
    }, 
    {
      path: '/home',name: 'Accueil',component: Accueil
    },
    {
      path: '/settings',name: 'Settings',component: Settings
    },
    {
      path: '/ness/profile',name: 'ProfileNess',component: Profile
    },
    {
      path: '/ness/dashboard',name: 'dashboardNess',component: DashboardNess
    },
    {
      path: '/ness/networks',name: 'reseauNess',component: Reseaux
    },
    {
      path: '/ness/histories',name: 'historiesNess',component: Histories
    },
    {
      path: '/ness/search',name: 'searchNess',component: Search
    },
    {
      path: '/ness/histories/:id', name: 'history', component: History
    },
    {
      path:'/ness/network/add/', name: 'addReseau',component: AddReseau
    },
    {
      path: '/ness/network-request/', name: 'networkRequest', component: StatutDemandeReseau
    },
    {
      path: '/ness/gest-networks', name: 'gestNetworks', component: NetworkGest
    },
    {
      path: '/ness/requests/', name: 'request', component: Demande
    },
    {
      path: '/ness/users/', name: 'usersNess', component: Users
    },
    {
      path: '/ness/user-search/:id', name: 'userSearch', component: UserHistory
    },
    {
      path: '/editQuestionnaire/:id',name: 'editQuestionnaire',component: editQuestionnaire
    }, 
    {
      path: '/themis',
      name: 'Themis',
      component: ThemisView,
      children: [
        {
          path: '', name:'homeThemis',
          component: ThemisHome,
        },
        {
          path: 'profile',name:'profileThemis',
          component: ThemisProfile,
        },
        {
          path: 'laborcode',name:'laborCodeThemis',
          component: LaborCode,
        },{
          path: 'collective-agreements',name:'agreementThemis',
          component : CollectiveAgreements
        },
        {
          path: 'my-company',name:'companyThemis',
          component : MyCompany
        },
        {
          path: 'my-company-admin',name:'companyAdminThemis',
          component : MyCompagnyAdmin
        },
        {
          path: 'faq',name:'faqThemis',
          component : Faq
        }, 
        {
          path: 'faq-gest',name:'faqGestThemis',
          component : FaqGest
        }, 
        {
          path: 'users',name:'userThemis',
          component : UserThemis
        }, 
        {
          path: 'favoris', name:'myFavoris',
          component: MyFavoris,
        },
        {
          path: 'notification', name:'notifications',
          component: Notification,
        },
        {
          path: 'myQuestions', name:'MyQuestions',
          component: MyQuestions,
        },
      ]
    }, 
    {
      path: '/pythie',
      name: 'Pythie',
      component: ThemisView,
      children: [
        {
          path: '', name:'dashboardPythie',
          component: DashboardPythie,
        },
        {
          path: 'profile', name:'profilePythie',
          component: ProfilePythie,
        },
        {
          path: 'user-profile/', name:'Userprofile-Pythie',
          component: UserprofilePythie,
        },
        {
          path: 'user-profile/:id', name:'UserprofilePythie',
          component: ProfileUser,
        },
        {
          path: 'career-plan', name:'CareerPlan',
          component: CareerPlan,
        },
        {
          path: 'activities-tracking', name:'ActivitiesTracking',
          component: ActivitiesTracking,
        },
        {
          path: 'activities-tracking/:id', name:'ActivitiesUser',
          component: ActivitiesUser,
        },
        {
          path: 'users', name:'UsersPythie',
          component: UsersPythie,
        },
        {
          path: 'assignFormation/:id', name:'AssignFormation',
          component: AssignFormation,
        },
      ]
    }
    
]
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})
export default router