<template>
    <div id="pythie">
        <Header :user="user" logo="pythie"/>
        <div class="container" style="margin-top: 8%;">
            <div v-show="showCollab && user.profile.user_rights_id > 2" class="alert alert-warning alert-dismissible fade show" role="alert" style="margin-bottom: 50px">
                <strong>Pythie administration : </strong>Vous êtes connecté en tant que {{ getUserRole() }}
                
                
            </div>
            <div class="dashboard" v-if="!showCollab" >
                <router-link to="/pythie/user-profile">
                    <Case  icon="fa-regular fa-user" :isIcon="true"  title="Profil" description="" class="profile" :user="user"/>
                </router-link>
                <router-link to="/pythie/career-plan">
                    <Case  :icon="require('@/assets/pythie/Plan.png')" title="Plan de carrière" description="" class="carreer"  :user="user" />
                </router-link>
                <router-link to="/pythie/activities-tracking">
                    <Case :icon="require('@/assets/pythie/suivi.png')"   title="Suivi d'activité" description="" :user="user" class="activities"  v-show="user.profile.user_rights_id > 1 "/>
                </router-link>
            </div>

            <div class="dashboard d-flex justify-content-center " v-else>
                <router-link :to="'/pythie/users'" v-if="user.Id">
                    <Case icon="fa-regular fa-user" :isIcon="true" title="Les utilisateurs" description="" class="teste_admin" :user="user"/>
                </router-link>
            </div>
        </div>
    </div>
    <Chatroom/>
</template>

<script setup>
import Header from '@/components/shared/Header.vue';

import Case from '@/components/pythie/Case.vue';
</script>

<script>
import store from '@/store';
import router from '@/router';
import Chatroom from '@/components/chatroom/Chatroom.vue';
 export default {
        name: 'DashboardView',
        data(){
            return{
                user: store.state.user,
                showCollab:store.state.showCollab
            }
        },
        methods: {
            redirectToTest(){
                router.push({ name: 'test', params: { id: this.user.id } })
            },
            getUserRole() {
                switch (this.user.profile.user_rights_id) {
                    case 3:
                        return "Gestionnaire";
                    case 4:
                        return "Administrateur";
                    case 5:
                        return "SuperAdmin";
                    default:
                        return "Utilisateur inconnu";
                }
            }
        },
        mounted(){
            //console.log('user : ', this.user);
        },
    }
</script>
<style>
#pythie{
        min-height: 100vh;
        background-image: url('@/assets/pythie/Background.jpg');
        background-size: cover; 
        background-repeat: no-repeat;
        overflow-y: auto; 
    }
.content{
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80%;
}
.dashboard{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: space-between;
    gap: 50px;
    flex-wrap: wrap;
}
.carreer{
    position: relative;
    top: 75px;
}
.profil{
    position: relative;
    top: 0px; 
}
/* .user{
    position: relative;
    top: 50px; 
} */
.activities{
    position: relative;
    top: 150px;
}
</style>