<template>
    <div class="popup">
        <div class="popup-inner" style="background-color: #06222d;">
            <slot />
            <div class="pl-5 pr-5">
                <p class="text-white text-left">Informations personnelles</p>
                <div class="row pl-5 pr-5">
                    <div class="col-md-6">
                        <input class="form-control fieldLogin" v-model="last_name" placeholder="Nom " required />
                    </div>
                    <div class="col-md-6">
                        <input class="form-control fieldLogin" v-model="first_name" placeholder="Prénoms" required />
                    </div>
                    <div class="col-md-6 mt-2">
                        <input class="form-control fieldLogin" v-model="email" placeholder="Adresse e-mail" required />
                    </div>
                    <div class="col-md-6 mt-2">
                        <input type="tel" class="form-control fieldLogin" v-model="phone" placeholder="Tel" required  ref="phoneInput" />
                    </div>
                    <div class="col-md-6 mt-2">
                        <input type="date" class="form-control fieldLogin" v-model="dob" placeholder="Date de Naissance"
                            required />
                    </div>
                    <div class="col-md-6 mt-2">
                        <input type="number" class="form-control fieldLogin" v-model="postal_code"
                            placeholder="Code postal" required />
                    </div>
                    <div class="col-md-6 mt-2">
                        <input type="text" class="form-control fieldLogin" v-model="city" placeholder="Ville"
                            required />
                    </div>
                    <div class="col-md-6 mt-2">
                        <input type="text" class="form-control fieldLogin" v-model="nationality"
                            placeholder="Nationalité" required />
                    </div>

                </div>
                <br>
                <p class="text-white text-left">Droit de l'utilisateur</p>
                <div class="col-md-12 mt-2  pr-5 pl-5">
                    <select class="form-control fieldLogin" :disabled="userRights < 4"  v-model="droit">
                        <option v-for="droit in userPermissions" :key="droit.id" :value="droit.id">{{ droit.description
                            }}</option>
                    </select>
                    <br>
                    <button v-show="userRights > 3" class="col-md-5 btn btn-primary " @click="updateProfile()">
                        Enregistrer</button>
                </div>
                <br>
                <!-- <p class="text-white text-left" >Groupe </p>
                <div class="row pl-5 pr-5">
                    <div class="col-md-4 pt-3 pb-3" style="background-color: white;">
                        <div class="group" v-for="group in groups" :key="group.id">
                            <p v-if="!hisGroup.includes(group.id)" @click="addToListToAdd(group.id)" :style="groupToAdd.includes(group.id) ? 'background-color:gold' : ''">{{ group.name }}</p>
                        </div>
                    </div>
                    <div class="col-md-4 d-flex justify-content-center align-items-center">
                        <div class="text-center">
                            <button class="btn btn-primary mb-3" :disabled="groupToAdd.length==0" @click="add()"> >> </button>
                            <br>
                            <button class="btn btn-primary" :disabled="groupToRemove.length==0" @click="remove()"> &lt;&lt; </button>
                        </div>
                    </div>
                    <div class="col-md-4 pt-3 pb-3" style="background-color: white;">
                        <div class="group" v-for="group in hisGroup" :key="group">
                            <p @click="addToListToRemove(group)" :style="groupToRemove.includes(group) ? 'background-color:gold' : ''" >{{ groupName[group] }}</p>
                        </div>
                    </div>
                </div>
                    <button class="col-md-2 btn btn-primary mt-2" @click="saveGroup()"> Enregistrer</button>
                <hr> -->

                <div v-show="userRights > 2"  class="row  pt-3">
                    <p class="col-md-6 text-white text-left">Réinitialiser mot de passe </p>

                    <button class="col-md-6 btn btn-primary "  @click="reinitform = true"> Reinitialiser</button>
                    <input v-if="reinitform" type="password" class="form-control col-md-6 mt-2 fieldLogin"
                        v-model="myPassword" placeholder="Saisir votre mot de passe" required />
                    <div class="col-md-1"></div>
                    <button v-if="reinitform" class="col-md-2 mt-2 btn btn-primary " @click="initPassword()"> Valider</button>
                    <div class="col-md-1"></div>
                    <button v-if="reinitform" class="col-md-2 mt-2 btn btn-secondary " @click="reinitform = false">
                        Annuler</button>
                  <div class="col-md-1"></div>

                </div>
                <button v-show="userRights > 3" class="col-md-4 btn btn-secondary mt-4" @click="$emit('close')"> Annuler</button>
                <button v-show="userRights < 4" class="col-md-4 btn btn-secondary mt-4" @click="$emit('close')"> Fermer</button>
                
                <!-- </form> -->
            </div>
        </div>
    </div>
</template>
<script>
import { getAllGroups, resetGroup, userRights } from '@/data/apiNess'
import { resetPassword, updateProfile } from '@/data/apiUser'
import store from '@/store';
import { useToast } from 'vue-toastification'
const toast = useToast()
export default {
    name: 'settingUser',
    props: ['selectedUser', 'userId', 'groupUser', 'userRights', 'right'],
    data() {
        return {
            user: store.state.user,
            category: 0,
            title: '',
            file: '',
            groups: [],
            groupName: [],
            hisGroup: [],
            groupToAdd: [],
            groupToRemove: [],
            rightUser: 0,
            myPassword: '',
            reinitform: false,
            userPermissions: [],


            last_name: '',
            first_name: '',
            email: '',
            phone: '',
            dob: '',
            droit: '',
            nationality: '',
            city: ''
        }
    },
    async mounted() {
        this.userPermissions = await userRights()

        this.last_name = this.selectedUser.last_name
        this.first_name = this.selectedUser.first_name
        this.phone = this.selectedUser.extension[0] ? this.selectedUser.extension[0].mobile_num : ''
        this.email = this.selectedUser.email
        this.dob = this.selectedUser.extension[0] ? this.selectedUser.extension[0].dob : ''
        this.postal_code = this.selectedUser.extension[0] ? this.selectedUser.extension[0].postal_code : ''
        this.city = this.selectedUser.extension[0] ? this.selectedUser.extension[0].city : ''
        this.nationality = this.selectedUser.extension[0] ? this.selectedUser.extension[0].nationality : ''

        this.droit = this.selectedUser.extension[0] ? this.selectedUser.extension[0].user_rights_id : ''
        this.groups = await getAllGroups()
        this.groups.forEach(item => {
            this.groupName[item.id] = item.name
        });
        this.groupUser.forEach(item => {
            this.hisGroup.push(item)
        })

        const input = this.$refs.phoneInput;
        const iti = window.intlTelInput(input, {
            initialCountry: 'fr',  
            utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js',
            nationalMode: false,
            autoHideDialCode: false,
            // separateDialCode: true 
        });
        input.addEventListener('input', () => {
            this.mobile  = iti.getNumber(window.intlTelInputUtils.numberFormat.INTERNATIONAL); // Format national avec les espaces
            input.value = this.mobile;
        });
        input.addEventListener('countrychange', () => {
            this.mobile = iti.getNumber();  
        });

    },
    methods: {

        chooseFile() {
            document.getElementById('btnUpload').click();
        },
        async reinit() {
          await resetPassword( store.state.user.Id, this.myPassword , this.userId)
            //console.log(result)
            //if (result.error) toast.error(result.error)
            //else toast.success(result.message)
            this.reinitform = false
        },
        addToListToAdd(id) {
            if (!this.groupToAdd.includes(id)) {
                this.groupToAdd.push(id)
            } else {
                let index = this.groupToAdd.indexOf(id)
                this.groupToAdd.splice(index, 1)
            }
        },
        addToListToRemove(id) {
            if (!this.groupToRemove.includes(id)) {
                this.groupToRemove.push(id)
            }
            else {
                let index = this.groupToRemove.indexOf(id)
                this.groupToRemove.splice(index, 1)
            }
        },
        add() {
            this.hisGroup.push(...this.groupToAdd)
            this.groupToAdd = []
        },
        remove() {
            this.groupToRemove.forEach(item => {
                let index = this.hisGroup.indexOf(item);
                if (index !== -1) {
                    this.hisGroup.splice(index, 1);
                }
            });
            this.groupToRemove = []
        },
        async saveGroup() {
            const payload = {
                "user_id": this.selectedUser.id,
                "groups": this.hisGroup
            }
            console.log(JSON.stringify(payload))
            const result = await resetGroup(JSON.stringify(payload))
            console.log(result)
            toast.success(result.Result)
            this.$emit('close')
            setTimeout(() => {
                window.location.reload()
            }, 1500)

        },
        async OnUpdateProdile(){
            let payload = {
                // "user_id" : this.selectedUser, 
                "postal_code": this.postal_code,
                "city": this.city,
                "street_name": "-",
                "mobile_num": this.phone,
                "nationality": this.nationality,
                "salary_expectations": "0",
                "availability": "2021-11-04",
                "company_id": 2,
                "user_rights_id": this.droit
            }
            // console.log(JSON.stringify(payload))
            const result = await updateProfile(this.selectedUser.extension[0] ? this.selectedUser.extension[0].id : 0, payload)

            if (result.id)
                toast.success("Mis à jour effectué avec succès")
            else toast.error("Une erreur est survenue")
            this.$emit('close')
            setTimeout(() => {
                window.location.reload()
            }, 500)
        },
        updateProfile() {
            const idUserConnected=this.user.Id;
            const idUserUpdated = this.userId;
            if (this.userRights === 3) { // Si l'utilisateur connecté est un gestionnaire
                if (this.right < 3 ) {
                    this.OnUpdateProdile();
                } else if (this.right === 3) {
                    if(idUserConnected === idUserUpdated){
                        this.OnUpdateProdile();
                    }else{
                        toast.error("Un gestionnaire ne peut pas modifier l'information d'un autre gestionnaire");
                    }
                } else if (this.right === 4) {
                    toast.error("Un gestionnaire ne peut pas modifier l'information d'un administrateur");
                } else if (this.right === 5) {
                    toast.error("Un gestionnaire ne peut pas modifier l'information d'un superAdmin");
                } else {
                    toast.error("Action non permise");
                }
            } else if (this.userRights === 4) { // Si l'utilisateur connecté est un administrateur
                if (this.right < 4) {
                    this.OnUpdateProdile();
                }else if (this.right === 4) {
                    if(idUserConnected === idUserUpdated){
                        this.OnUpdateProdile();
                    }else{
                        toast.error("Un administrateur ne peut pas modifier l'information d'un autre administrateur");
                    }
                }else if (this.right === 5) {
                    toast.error("Un administrateur ne peut pas modifier l'information d'un superAdmin");
                } else {
                    toast.error("Action non permise");
                }
            } else if (this.userRights === 5 && this.right <= 5 ) { // Si l'utilisateur connecté est un superAdmin
                        this.OnUpdateProdile();
            } else {
                toast.error("Droits non autorisés pour effectuer cette action");
            }

        },

        initPassword() {
            const idUserConnected=this.user.Id;
            const idUserUpdated = this.userId;
           if (this.userRights === 3) { // Si l'utilisateur connecté est un gestionnaire
                if (this.right === 3) {
                    if(idUserConnected === idUserUpdated){
                        this.reinit();
                    }else{
                        toast.error("Un gestionnaire ne peut pas reinitialiser le mot de passe d'un autre gestionnaire");
                    }
                } else if (this.right === 4) {
                    toast.error("Un gestionnaire ne peut pas reinitialiser le mot de passe d'un administrateur");
                } else if (this.right === 5) {
                    toast.error("Un gestionnaire ne peut pas reinitialiser le mot de passe d'un superAdmin");
                } else if (this.right === 1 || this.right === 2) {
                    this.reinit(); 
                
                } else {
                    toast.error("Action non permise");
                }
            } else if (this.userRights === 4) { // Si l'utilisateur connecté est un administrateur
                if (this.right === 4) {
                    if(idUserConnected === idUserUpdated){
                        this.reinit();
                    }else{
                        toast.error("Un administrateir ne peut pas reinitialiser le mot de passe d'un autre administrateur");
                    }
                } else if (this.right === 5) {
                    toast.error("Un administrateur nne peut pas reinitialiser le mot de passe  d'un superAdmin");
                } else if (this.right === 3 || this.right === 2 || this.right === 1) {
                    this.reinit(); 
                } else {
                    toast.error("Action non permise");
                }
            } else if (this.userRights === 5 && this.right <= 5 ) { // Si l'utilisateur connecté est un superAdmin
                         this.reinit(); 
                
            } else {
                toast.error("Droits non autorisés pour effectuer cette action");
            }
            
        }
       

     
    }
}
</script>
<style>
.group p {
    cursor: pointer;
}

.form-control.fieldLogin:disabled {
    background-color: #555d64; 
    border: 1px solid #ddd;   
    color: white;               
    cursor: not-allowed;       
}
</style>