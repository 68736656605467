<template>
    <div class="popup">
        <div class="popup-inner" style="background-color: #06222d;">
            <slot/>
            <div class="pl-5 pr-5">
                
                <p class="text-white text-left" > </p>
                <input type="email" class="form-control fieldLogin" v-model="email" placeholder="Saisir votre adresse mail " required/>
                {{ email }}
                <hr>
                <div class="row pl-5 pr-5">
                    <button class="col-md-5 btn btn-secondary " @click="$emit('close')"> Annuler</button>
                    <div class="col-md-2"></div>
                    <button class="col-md-5 btn btn-primary" :disabled="email==''" @click="validEmail()"> Continuer</button>
                    
                </div>
            <!-- </form> -->
            </div>
        </div>
    </div>
</template>
<script>
import {  sendAdressMail} from '@/data/apiUser'
// import store from '@/store';
import { useToast } from 'vue-toastification'
const toast = useToast()
    export default{
        name: 'forgetPassword', 
        
        data(){
            return{
                idUser : 0,
                new_password : '',
                confirm_password : '', 
                email : ''
            }
        },
        async mounted(){
            
        },
        methods:{
            async validEmail(){
                const result = await sendAdressMail(this.email.toLowerCase())
                console.log(result)
                if(result.error) toast.error(result.error)
                else toast.success("Un mail vous sera envoyé")
                this.reinitform = false
            }
            
           
        }
    }
</script>