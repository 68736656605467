<template>
    <div class="popup">
        <div class="popup-inner">
            <slot/>
            <div class="  p-3 ">  
                <button class="btn btn-warning text-white" @click="importFile=!importFile">
                    <span v-if="!importFile"><i class="fas fa-file-import"></i> Importer fichier Excel</span>
                    <span v-else>Formulaire</span>
                </button>
                <form class="p-4">
                    <div class="form-group" v-if="importFile">
                        <input type="file" class="form-control" id="file"  @change="uploadFile($event)"/>
                    </div>
                    <div class="pb-3" v-else>
                        <div class="form-group">
                            <label for="title" class="float-left text-white">Question</label>
                            <input type="text" class="form-control" v-model="question" id="question"/>
                        </div>
                        <div class="form-group" >
                            <label for="responses" class="float-left text-white">Réponses (séparées par '|||')</label>
                            <input type="text" class="form-control" v-model="responses" id="responses"/>
                        </div>
                        <div class="form-group">
                            <label for="correctAnswer" class="float-left text-white">Bonne(s) réponse(s)</label>
                            <input type="text" class="form-control" v-model="correctAnswer" id="correctAnswer"/>
                        </div>
                        <div class="form-group">
                            <label for="level" class="float-left text-white">Niveau</label>
                            <input type="number" min="1" max="3" class="form-control" v-model="difficulty" id="level"/>
                        </div>
                        <button v-if="!showConfirm" type="button" class="btn btn-primary float-right" @click="add" :hidden="question=='' || responses=='' || correctAnswer==''">Ajouter</button>
                    </div>
                    <div v-if="questions.length>0 && showConfirm">
                        <div class="questions" v-for="(question, index) in questions" :key="index">
                            <ul>
                                <li><span class="float-left text-white">{{ question.question }}</span><span class="float-right" @click="removeQuestion(index)"><i class="fas fa-trash-can"></i></span></li>
                            </ul>
                        </div>
                        <!-- <p v="else" class="text-white">Ces questions existent déjà</p> -->
                        <button type="button" class="btn btn-secondary m-2" @click="togglePopup()" >Fermer</button>
                        <button type="button" class="btn btn-success m-2" @click="addQuestion" :disabled="questions.length==0">Terminer</button>
                    </div>
                    
                </form> 
                <hr>
            </div>
        </div>
    </div>
</template>
<script>
import readXlsxFile from 'read-excel-file'
import axios from 'axios';
import { useToast } from "vue-toastification";

const toast = useToast()
    import data from '@/data/data';
    export default{
        name: 'addQuestion',
        props:['idQuestionnaire', "theme", "questionsTheme", "togglePopup"],
        data(){
            return {
                question : '',
                responses : '',
                correctAnswer : '',
                difficulty : 1, 
                questions : [], 
                importFile : false, 
                existingQuestion : [], 
                showConfirm : false
            }
        }, 
        mounted(){
            this.questionsTheme.forEach(item=>{
                this.existingQuestion.push(item.question)
            })
        },
        methods:{
            uploadFile(event){
                let xlsxfile = event.target.files ? event.target.files[0] : null;
                readXlsxFile(xlsxfile).then((rows) => {
                    rows.forEach((element, index) => {
                        if(index!=0)
                            if(!this.existingQuestion.includes(element[0])){
                                this.questions.push({
                                    question : element[0], 
                                    answers : element[1],
                                    correctAnswer : element[2],
                                    difficulty : element[3],
                                })
                            }
                    }); 
                    if(this.questions.length==0){
                        toast.error("Veuillez verifier les questions car elles existent déjà! ")
                    }
                    this.showConfirm = true
                })
            },
            add(){
                this.questions.push({
                    question : this.question, 
                    answers : this.responses,
                    correctAnswer : this.correctAnswer,
                    difficulty : this.difficulty,
                })
                console.log(this.questions)
                this.showConfirm = true
            },
            removeQuestion(index){
                this.questions.splice(index,1)
            },
            addQuestion(){
                var payload = {
                    "newQuestionnaire" : {
                        "Title" :  this.theme.Title,
                        "linkedJob" : [this.theme.linkedJob],
                        "category" : this.theme.category,
                        "isArchived" : this.theme.isArchived, 
                        "dateArchived" : this.theme.dateArchived,
                    },"questions" : this.questions
                }
                // console.log(payload)
                if(this.questions.length>0)
                axios.post(data.baseUrl + 'thotea/addQuestionnaire/', payload)
                    .then(response=>{
                        if(response.data){
                            toast.success("Questions ajoutées avec succès")
                            this.togglePopup()
                            setTimeout(() => {
                                window.location.reload();
                            }, 1500);
                        }
                    }).catch(error=> console.log(error))
            },  
        }
    }
</script>
<style>
.popup{
    position: fixed;
    top:0;
    left : 0;
    right: 0;
    bottom : 0;
    z-index: 99;
    background-color: rgba(0,0, 0,0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.popup-inner{
        background-color: #280913fa;
        border: 1px solid;
        border-color: #fab83380;
        width: 50%;
        padding: 32px;
        max-height: 100vh;
        overflow-y: auto;
    }
.questions{
    max-height: 40vh;
    overflow-y: auto;
}
</style>