<template>
    
        <Header :user="user" logo="themis" />
        <div class="p-3">     
            <div class="row">
                <back-button />
                <br>
            </div>  
            <div class="row pl-5 pr-5 pt-3 align-items-center" >
                <div class="col-md-1"></div>
                <div class="col-md-4">
                    <img src="@/assets/themis/profile.png" class="profile" />
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-4 card px-4 py-2 pl-6 p-5 cardProfile">
                    <p class="name">{{ user.Firstname }} {{ user.Lastname }}</p>
                    <p class="textTitle">Profil technique : {{ job.title }} </p>
                    <p class="textTitle">Nationalité : {{ user.profile.nationality }} </p>
                    <p class="textTitle">Adresse : {{ user.profile.street_name }} {{ user.profile.city }} </p>
                    <p class="textTitle">Mail : {{ user.Email }}</p>
                    <p class="textTitle">No téléphone : {{ formatPhoneNumber(user.profile.mobile_num) }}</p>
                </div>
                <div class="col-md-1"></div>
            </div>
            <br><br>
            <div class="buttons">
                <router-link to="/themis/notification">
                    <div class="button">
                        <img src="@/assets/themis/icon-notification.png" alt="notifications">
                        <p>Mes notifications</p>
                    </div>
                </router-link>
                <router-link to="/themis/myQuestions">
                    <div class="button" >
                        <img src="@/assets/themis/icon-question.png" alt="questions">
                        <p>Mes questions</p>
                    </div>
                </router-link>
            </div>
        </div>
    <Chatroom/>
            
</template>
<script setup>
    import Header from '@/components/shared/Header.vue';
    import BackButton from '@/components/shared/BackButton.vue';
</script>
<script>
import store from '@/store';
import Chatroom from '@/components/chatroom/Chatroom.vue';
import { formatPhoneNumber } from '@/data/formatPhoneNumber';
export default {
    name:"ThemisProfile",
    components:{BackButton},
    data(){
        return {
            user : store.state.user, 
            job : {}
        }
    }, 
}
</script>
<style scoped>
.cardProfile{
    border-radius: 30px ;
    text-align: start;
}
.cardProfile p{
    margin: 0;
    padding: 1px 0;
}
.cardProfile p.name{
    color: #008dae;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 30px;
}
.buttons{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 300px;
} 
 .button{
    background-color: #fff;
    width: 150px;
    height: 150px;
    border-radius: 100%;
} 
 .button img{
    width: 50%;
    height: 50%;
    margin-top: 20px;
}
.button p{
    color: #fab833;
}
.profile{
    width: 80%;
    height: 80%;
    display: block;
}
</style>