import axios from 'axios';
import data from '@/data/data';
import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import jwt_decode from 'jwt-decode';

let user = null;
try {
  user = JSON.parse(sessionStorage.getItem('user'));
} catch (e) {
  console.error(e);
}

if (!user) {
  user = undefined;
}

let showCollab = sessionStorage.getItem('showCollab') === 'true'; // Récupérer la valeur de sessionStorage

export default createStore({
  state: {
    user: user,
    showCollab: showCollab, 
    isRefresh: false,
  },
  getters: {},
  mutations: {
    UPDATE_USER(state, user) {
      state.user = user;
    },
    toggleShowCollab(state, showCollab) {
      state.showCollab = showCollab;
      sessionStorage.setItem('showCollab', showCollab ? 'true' : 'false'); // Mettre à jour sessionStorage
    },
  },
  actions: {
    login(context, info) {
      return new Promise((resolve, reject) => {
        axios.post(data.baseUrl + "api/token/", info)
          .then(response => {
            if (!response.data.Error) {
              const decoded = jwt_decode(response.data.access);
              const { user_id } = decoded;

              axios.post(data.baseUrl + "authentication/api/user/", { "id": user_id })
                .then(response1 => {
                  context.commit('UPDATE_USER', response1.data);
                  sessionStorage.setItem('user', JSON.stringify(response1.data));

                  localStorage.removeItem('vuex');
                  resolve(response);
                })
                .catch(error1 => {
                  console.log("Erreur : " + error1);
                  reject(error1);
                });

            } else {
              resolve(response);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    logout(context) {
      sessionStorage.removeItem('user');
      context.commit('UPDATE_USER', undefined);
      sessionStorage.removeItem('showCollab'); 
    }
  },
  modules: {},
  plugins: [createPersistedState()],
});
