<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  mounted() {
    window.addEventListener('mousemove', function () {
        if(localStorage.getItem('vuex')){
          localStorage.removeItem('vuex');
        }
      
      })
  },
 
  
};
</script>

<style>
#app {
  font-family:Century Gothic;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;
  
}

.iti {
  width: 100% !important;
  color: black;
  display: block !important;
}

@import "~bootstrap/dist/css/bootstrap.css";
</style>
