<template>
    <div id="thotea">
        <Header :user="user" page="candidat" logo="thotea" />
        <!-- <button class="thoteaBg btn rounded-circle float-left m-2" @click="back()"><i class="fas fa-arrow-left" -->
                <!-- color="white"></i></button> -->
        <back-button></back-button>
        <div class="p-3">
            <h1 class="pt-5 textTitle" v-if="candidate && list.length>0">Résultat de {{ candidate.Firstname }} {{ candidate.Lastname }} </h1>
            
            <div class="row p-3"  v-show="list.length>0" >
                <div class="col-md-4 mt-5 pr-3 pl-3 pb-5 " v-if="item" style="margin-top: 5%!important;">
                    <div class="resultat"  >
                        <my-results :methods="viewResults" :list="list" />
                        <hr />
                    </div>
                </div>
                <div class="col-md-4">
                    <div style="margin-top: 5px;" id="chart">
                        <apexchart type="radar" :options="chartOptions" :series="series"></apexchart>
                    </div>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-3 mt-5" v-if="item" style="margin-top: 7%!important;">
                    <my-box :item="item" />
                </div>
            </div>
            <div class="questionAssigned pl-4 pr-4" style="max-height: 50vh;overflow-y: auto;">
                <h2 class="p-3 text-center  resultTitle textTitle">Questionnaire(s) assigné(s)</h2>
                <hr />
                <table class="table table-borderless text-white" v-if="questionnaireAssigned.length > 0"
                    style="max-height: 40vh;overflow-y: auto;">
                    <thead>
                        <th></th>
                        <th class="text-center">Questionnaire</th>
                        <th class="text-center">Difficulté</th>
                        <th class="text-center">Temps(en minutes)</th>
                        <th class="text-center">Nbre questions</th>
                        <th></th>
                    </thead>
                    <tr v-for="questionnaire in questionnaireAssigned" :key="questionnaire.id">
                        <td><i class="fa fa-question-circle " style="color: #cba415;"></i>

                        </td>
                        <td> {{ questionnaire.questionnaireAssigned.Title }} </td>
                        <td> 1 - 3 </td>
                        <td> {{ questionnaire.time }} </td>
                        <td> {{ questionnaire.numberOfQuestion }} </td>
                        <td> 
                            <button class="btn btn-outline-warning" v-if="!questionnaire.isArchived" @click="openDeleteConfirmation(questionnaire)"
                                data-toggle="tooltip" data-placement="top" title="Retirer questionnaire assigné"> Annuler
                                test</button>
                            <span @click="ShowDetails(questionnaire)" class="btn btn-outline-light" v-else>{{ formatDate(questionnaire.dateTest, 'D MMMM YYYY à HH:mm') }}</span>
                        </td>
                    </tr>
                </table>
                <p v-else>Aucun questionnaire assigné</p>
            </div>
        </div>
        
          <ConfirmDeleteAssignedQuestion
            v-if="showDeleteConfirmation"
            :title="selectedQuestionnaire.questionnaireAssigned.Title"
            :type="'supprimer '"
            :textConfirmButton="'Supprimer'"
            :modalBackgroundColor="'#9a4c76'"
            :borderColor="'green'"
            :confirmButtonColor="'#dc3545'"
            :confirmButtonTextColor="'white'"
            :cancelButtonColor="'#17a2b8'"
            :cancelButtonTextColor="'white'"
            :overlayColor="'rgba(0,0,0,0.9)'"
            @confirm="handleDeleteConfirmation"
            @close="closeDeleteConfirmation"
            />
    </div>
    <Chatroom />
</template>

<script setup>
    import Chatroom from '@/components/chatroom/Chatroom.vue';
</script>
<script>
import store from '@/store'
import Header from '@/components/shared/Header.vue';
import axios from 'axios';
import data from '@/data/data';
import functions from '@/data/function';

import VueApexCharts from 'vue3-apexcharts';
import BackButton from '@/components/shared/BackButton.vue';
import MyResults from '@/components/MyResults.vue';
import MyBox from '@/components/MyBox.vue';
import moment from 'moment';
import 'moment/locale/fr'
import { useToast } from 'vue-toastification';
import ConfirmDeleteAssignedQuestion from '@/components/ConfirmDeleteModal/DeleteConfirmModal.vue';
moment.locale('fr');
const toast = useToast()
export default {
    name: 'listQuestionnaire',
    props: {

    },
    components: { Header, apexchart: VueApexCharts , BackButton, MyResults, MyBox , ConfirmDeleteAssignedQuestion},

    data() {
        return {
            user: store.state.user,
            idCandidate: this.$route.params.id,
            candidate: undefined,
            list: [],
            questionnaireAssigned: [],
            allSeries: [],
            labels: [],
            series: [],
            chartOptions: {},
            item: undefined,
            quizzesHistoric: [],

            // Gestion de la suppression
            showDeleteConfirmation: false,
            selectedQuestionnaire: null,
        }
    },
    async updated(){
        if (this.questionnaireAssigned.length>0 && this.quizzesHistoric.length>0){
            this.questionnaireAssigned.forEach(questionnaire => {
                questionnaire.dateTest = this.quizzesHistoric.find(item => item.assigned_questionnaire === questionnaire.id)?.dateTest
            })
            this.questionnaireAssigned = this.questionnaireAssigned.sort((a, b) =>
                (!a.dateTest && !b.dateTest)
                    ? 0
                    : !a.dateTest
                    ? 1
                    : !b.dateTest
                    ? -1
                    : new Date(b.dateTest) - new Date(a.dateTest)
            );
        }
    },
    async mounted() {

        if (this.user.profile.user_rights_id <= 2) this.$router.push("/")
        axios.get(data.baseUrl + 'thotea/api/getAssignQuestionnaires/?bulmaId=' + this.idCandidate)
            .then(response => {
                response.data.forEach(element => {
                    this.questionnaireAssigned.push(element)
                });
            }).catch(error => console.log(error))
        axios.get(data.baseUrl + 'thotea/api/questiongrade/?userId=' + this.idCandidate)
            .then(response => {
                this.quizzesHistoric = response.data.filter(item => item.assigned_questionnaire)
            }).catch(error => console.log(error))
        await axios.post(data.baseUrl + "authentication/api/user/", { "id": this.idCandidate })
            .then(response => {
                this.candidate = response.data;
                axios.get(data.baseUrl + "thotea/api/questiongrade/?userId=" + this.candidate.Id + "&qtyTest=15")
                    .then(response => {
                        this.list = response.data;
                        this.item = this.list[0]
                        this.list.forEach(item => {
                            this.allSeries.push(item.results)
                            this.labels.push(item.name)
                        })

                        this.series = [{
                            name: 'Series 1',
                            data: this.allSeries,
                        }]

                        this.chartOptions = {
                            chart: {
                                height: 600,
                                type: 'radar',
                                dropShadow: {
                                    enabled: true,
                                    blur: 1,
                                    left: 1,
                                    top: 1
                                }
                            },
                            title: {
                                text: ''
                            },
                            stroke: {
                                width: 2
                            },
                            fill: {
                                opacity: 0.1
                            },
                            markers: {
                                size: 0
                            },
                            xaxis: {
                                categories: this.labels
                            }
                        }
                    }).catch(error => console.log(error))
            }).catch(error => console.log(error))


    },
    methods: {
        styleByResult(result) {
            if (result >= 50) {
                return "background-color: forestgreen;"
            }
            else if (result < 50 && result >= 20) {
                return "background-color: hsl(49, 90%, 45%);"
            }
            return "background-color: red;"
        },
        viewResults(index) {
            this.item = this.list[index];
        }, 
        clickDetails(idUser, idQuest) {
            this.$router.push("/questionnaireUserAnswer/"+idUser+"/"+idQuest);
        },
        back() {
            functions.goBack()
        },
        formatDate(date, format){
            const parsedDate = moment(date, moment.ISO_8601, true);
            return parsedDate.isValid()?'Effectué le : ' + parsedDate.format(format) : ' - '
        },
        ShowDetails(quizz) {
            console.log('quizz to redirect : ', quizz);
            this.$router.push("/DetailResult/" + quizz.id + '/' + quizz.userID);
        },
        // Méthodes pour la suppression
        openDeleteConfirmation(questionnaire) {
            this.selectedQuestionnaire = questionnaire;
            this.showDeleteConfirmation = true;
        },

        closeDeleteConfirmation() {
            this.showDeleteConfirmation = false;
            this.selectedQuestionnaire = null;
        },

        async handleDeleteConfirmation() {
        if (this.selectedQuestionnaire) {
            await axios.delete(data.baseUrl + 'thotea/api/assignquestionnaires/' + this.selectedQuestionnaire.id + '/')
            .then(response => {
                console.log(response);
                toast.success('Questionnaire retiré avec succès');
                this.questionnaireAssigned = this.questionnaireAssigned.filter(
                item => item.id !== this.selectedQuestionnaire.id
                );
            })
            .catch(error => console.log(error));
        }
        this.closeDeleteConfirmation();
        },
        
    },
}
</script>
<style>
.col3 {
    border-radius: 3%;
}
.resultat::-webkit-scrollbar{
    background:transparent !important;
}
.questionAssigned::-webkit-scrollbar{
    background:transparent !important;
}
</style>